import React from 'react'
import { login } from '../../Services/auth'
import { CircularProgress } from '@material-ui/core'
import api from '../../Services/api'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from 'reactstrap'

class Login extends React.Component {
  state = {
    email: '',
    senha: '',
    error: '',
    loading: false
  }

  handleSingIn = async e => {
    e.preventDefault()
    const { email, senha } = this.state
    this.setState({ loading: true })
    if (!email || !senha) {
      this.setState({ error: 'Preencha e-mail e senha para continuar!' })
    } else {
      try {
        const response = await api.post('/admin/auth', {
          email: email,
          pass: senha
        })
        const resp = response.data.response.message
        if (response.data.status === false) {
          this.setState({ loading: false })
          this.setState({
            error: 'Houve um problema com o login, verifique suas credenciais.'
          })
        } else {
          login(resp.uuidAdmin, resp.adminName)
          this.props.history.push('/admin/filmes')
        }
      } catch (err) {
        this.setState({ loading: false })
        this.setState({
          error: 'Houve um problema com o login, verifique suas credenciais.'
        })
      }
    }
  }

  render () {
    const { loading } = this.state
    return (
      <>
        <Col lg='5' md='7'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-5'>
              <Form role='form' onSubmit={this.handleSingIn}>
                {this.state.error && <p>{this.state.error}</p>}
                <FormGroup className='mb-3'>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-email-83' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      autoFocus
                      placeholder='Email'
                      type='email'
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Senha'
                      type='password'
                      onChange={e => this.setState({ senha: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <div className='text-center'>
                  <Button className='my-4' color='primary' type='submit'>
                    {!loading ? (
                      'Entrar'
                    ) : (
                      <CircularProgress color='inherit' size={20} />
                    )}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className='mt-3'>
            <Col xs='6'>
              <a
                className='text-light'
                href='#pablo'
                onClick={e => e.preventDefault()}
              >
                <small>Esqueci minha senha</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    )
  }
}

export default Login
