import React, { Component } from 'react'
import {
	Card,
	CardHeader,
	CardFooter,
	Container,
	Row,
	Button,
	Table,
	UncontrolledDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Pagination,
	PaginationItem,
	PaginationLink,
} from 'reactstrap'
import { Modal } from '@material-ui/core'
import api from '../../../Services/api'
import SuccessModal from '../../../components/SuccessModal'
import AlertModal from '../../../components/AlertModal'
import { Link } from 'react-router-dom'
import Header from '../../../components/Headers/Header.jsx'

let prev = 0
let last = 0
class Episodes extends Component {
	constructor({ match }) {
		super()
		this.state = {
			id_ss: match.params.id_temp,
			id_sr: match.params.id_sr,
			requests: [],
			currentPage: 1,
			todosPerPage: 2,
			reqSuccess: false,
			loading: false,
			addEpisode: false,
			currentSerie: null,
			currentSeason: null,
			currentEpisode: null,
			successModal: false,
			alertModal: false,
			removeModal: false,
			editEpisode: false
		}
		this.handleClick = this.handleClick.bind(this)
		this.handleLastClick = this.handleLastClick.bind(this)
		this.handleFirstClick = this.handleFirstClick.bind(this)
	}

	async componentWillMount() {
		this.setState({ currentSerie: this.state.id_sr })
		this.setState({ currentSeason: this.state.id_ss })
		this.setState({ loading: true })
		await api
			.post('/episodios', {
				id_season: this.state.id_ss
			})
			.then(res => {
				this.setState({
					reqSuccess: res.data.status,
					requests: res.data.response.message
				})
				this.setState({ loading: true })
			})
			.catch(error => console.log(error))
		this.setState({ loading: false })
		this.setState({
			requests: [...this.state.requests]
		})
		this.setState({ loading: false })
	}

	buscarId(id) {
		this.setState({
			id: id
		})
		return id
	}

	confirmExclude = e => {
		e.preventDefault()
		api
			.post('/episodios/deletar', {
				id_episode: this.state.id
			})
			.then(response => {
				setTimeout(window.location.reload(), 1000)
			})
			.catch(error => {
				console.log(error)
			})
	}

	handleClick(e) {
		e.preventDefault()
		this.setState({
			currentPage: Number(e.target.id)
		})
	}

	handleLastClick(e) {
		e.preventDefault()
		this.setState({
			currentPage: last
		})
	}
	handleFirstClick(e) {
		e.preventDefault()
		this.setState({
			currentPage: 1
		})
	}

	toggleModal = modal => {
		this.setState(state => ({
			[modal]: !state[modal]
		}))
	}

	render() {
		const { onClose } = this.props
		let {
			requests,
			currentPage,
			todosPerPage,
			addEpisode,
			currentSerie,
			currentEpisode,
			currentSeason,
			successModal,
			alertModal,
			removeModal,
			editEpisode
		} = this.state

		let indexOfLastTodo = currentPage * todosPerPage
		let indexOfFirstTodo = indexOfLastTodo - todosPerPage
		let currentTodos = requests.slice(indexOfFirstTodo, indexOfLastTodo)
		prev = currentPage > 0 ? currentPage - 1 : 0
		last = Math.ceil(requests.length / todosPerPage)

		let pageNumbers = []
		for (let i = 1; i <= last; i++) {
			pageNumbers.push(i)
		}

		return (
			<React.Fragment>
				<Header />
				<Container className='mt--7' fluid>
					<Row>
						<div className='col'>
							<Card className='shadow'>
								<CardHeader className='border-0'>
									<h3 className='mb-0'>Episódios</h3>
								</CardHeader>
								<Table>
									<thead className='thead-light'>
										<tr>
											<th scope='col'>Episódio</th>
											<th scope='col'>Duração do Episódio</th>
											<th scope='col'>Poster</th>
											<th scope='col' />
										</tr>
									</thead>
									<tbody>
										{this.state.loading ? (
											<i className='fa fa-spinner fa-pulse' />
										) : null}
										{this.state.reqSuccess ? (
											currentTodos.map((result, key) => {
												return (
													<>
														<tr key={key}>
															<th scope='row' className='serie-title'>
																<p className='mb-0 text-sm'>{result.title_episode}</p>
															</th>
															<td>{result.duration_episode}</td>
															<td>
																{result.poster_episode === '' ||
																	result.poster_episode === null ? (
																		<img
																			className='img-thumbnail'
																			alt='...'
																			style={{ display: 'none' }}
																		/>
																	) : (
																		<img
																			className='img-thumbnail'
																			alt='...'
																			src={result.poster_episode}
																		/>
																	)}
															</td>
															<td className='text-right'>
																<UncontrolledDropdown>
																	<DropdownToggle
																		className='btn-icon-only text-light'
																		href='#'
																		role='button'
																		size='sm'
																		color=''
																		onClick={e => e.preventDefault()}
																	>
																		<i className='fas fa-ellipsis-v' />
																	</DropdownToggle>
																	<DropdownMenu className='dropdown-menu-arrow' right>
																		<Link to={`/admin/episodio/editar/${result.id_episode}`}>
																			<DropdownItem>Editar</DropdownItem>
																		</Link>
																		<DropdownItem
																			onClick={() => {
																				this.toggleModal('removeModal')
																				this.buscarId(result.id_episode)
																			}}
																		>
																			Remover
                              </DropdownItem>
																	</DropdownMenu>
																</UncontrolledDropdown>
															</td>
														</tr>
													</>
												)
											})
										) : (
												<h1 style={{ margin: '20px' }}>
													{this.state.loading === true
														? ''
														: 'Ainda não existe episódios cadastrados!'}
												</h1>
											)}
									</tbody>
									<ul id='page-numbers'>
										<nav>
											<Pagination>
												<PaginationItem>
													{prev === 0 ? (
														<PaginationLink disabled>{'<<'}</PaginationLink>
													) : (
															<PaginationLink
																onClick={this.handleFirstClick}
																id={prev}
																href={prev}
															>
																{'<<'}
															</PaginationLink>
														)}
												</PaginationItem>
												<PaginationItem>
													{prev === 0 ? (
														<PaginationLink disabled>{'<'}</PaginationLink>
													) : (
															<PaginationLink
																onClick={this.handleClick}
																id={prev}
																href={prev}
															>
																{'<'}
															</PaginationLink>
														)}
												</PaginationItem>
												{pageNumbers.map((number, i) => (
													<Pagination key={i}>
														<PaginationItem
															active={pageNumbers[currentPage - 1] === number}
														>
															<PaginationLink
																onClick={this.handleClick}
																href={number}
																key={number}
																id={number}
															>
																{number}
															</PaginationLink>
														</PaginationItem>
													</Pagination>
												))}

												<PaginationItem>
													{currentPage === last ? (
														<PaginationLink disabled>{'>'}</PaginationLink>
													) : (
															<PaginationLink
																onClick={this.handleClick}
																id={pageNumbers[currentPage]}
																href={pageNumbers[currentPage]}
															>
																{'>'}
															</PaginationLink>
														)}
												</PaginationItem>

												<PaginationItem>
													{currentPage === last ? (
														<PaginationLink disabled>{'>>'}</PaginationLink>
													) : (
															<PaginationLink
																onClick={this.handleLastClick}
																id={pageNumbers[currentPage]}
																href={pageNumbers[currentPage]}
															>
																{'>>'}
															</PaginationLink>
														)}
												</PaginationItem>
											</Pagination>
										</nav>
									</ul>

									{removeModal && (
										<Modal
											open
											onClose={() => this.toggleModal('removeModal')}
											onSuccess={() => {
												this.toggleModal('removeModal')
												this.toggleModal('successModal')
											}}
											onError={() => this.toggleModal('alertModal')}
										>
											<div className='alert__content'>
												<h1 className='alert__title'>Aviso</h1>
												<h2 className='alert__subheader'>
													Deseja realmente excluir?
                  </h2>
												<button
													className='alert__button'
													onClick={this.confirmExclude}
												>
													Excluir
                  </button>
											</div>
										</Modal>
									)}

									<SuccessModal
										show={successModal}
										onClick={() => this.toggleModal('successModal')}
									/>

									<AlertModal
										show={alertModal}
										onClick={() => this.toggleModal('alertModal')}
										title='Preencha todos os campos'
										subheader='É necessário informar todos os dados para editar um episódio!'
									/>
								</Table>
								<CardFooter className='py-4'>
									<nav aria-label='...' />
									<Link
										to={`/admin/episodio/adicionar/${this.state.id_sr}/${
											this.state.id_ss
											}`}
									>
										<Button
											color='primary'
											className='py-2 px-3'
											size='sm'
											onClick={() => this.toggleModal('addSeason')}
											style={{ margin: '20px' }}
										>
											Adicionar Episodio
              </Button>
									</Link>
								</CardFooter>
							</Card>
						</div>
					</Row>
					<SuccessModal
						show={successModal}
						onClick={() => this.toggleModal('successModal')}
					/>

					<AlertModal
						show={alertModal}
						onClick={() => this.toggleModal('alertModal')}
						title='Preencha todos os campos'
						subheader='É necessário informar todos os dados para cadastrar um novo filme!'
					/>
				</Container>
			</React.Fragment>
		)
	}
}

export default Episodes
