import React from 'react'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input
} from 'reactstrap'
import { CircularProgress } from '@material-ui/core'
import Header from '../../../components/Headers/Header.jsx'
import api from '../../../Services/api'
import SnackBar from '../../../components/Snackbar/index.js'

export default class AddProgram extends React.Component {
  constructor({ match }) {
    super()
    this.state = {
      id_serie: match.params.id,
      season_number: '',
      director: '',
      directorReq: [],
      loading: false,
      directors: '',
      total_episodes: '',
      newDirector: ''
    }
  }

  componentDidMount() {
    this.directorRequest()
  }

  directorRequest = () => {
    api
      .post('/elenco')
      .then(res => {
        this.setState({ directorReq: res.data.response.message })
      })
      .catch(error => console.log(error))
    this.setState({
      directorReq: [...this.state.directorReq]
    })
  }

  directorSelect = () => {
    const resultado = this.state.directorReq.map(
      result =>
        (this.state.director = {
          value: result.id_ct,
          label: result.name_director
        })
    )
    return resultado
  }

  handleDirectorChange = directors => {
    let newdata = []
    if (directors === null) {
      directors = 0
    }
    for (let i = 0; i < directors.length; i++) {
      newdata.push({ director: directors[i].label })
    }

    this.setState({ newDirector: newdata })
    this.setState({ directors })
  }

  handleInputChange = e => {
    const { name, value } = e.target
    this.setState(() => ({ [name]: value }))
  }

  submitForm = e => {
    e.preventDefault()
    const { season_number, total_episodes, newDirector, id_serie } = this.state

    if (!total_episodes) {
      this.setState({
        snack: true,
        snackMessage: 'Prrencha todos os dados necessários.',
        snackVariant: 'error'
      })
    }
    this.setState({ loading: true })
    api
      .post('/temporadas/inserir', {
        id_serie: id_serie,
        cast: newDirector,
        season_number: season_number,
        total_episodes: total_episodes
      })
      .then(response => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: 'Temporada adicionada com sucesso!',
          snackVariant: 'success'
        })
        setTimeout(() => {window.location.href = `/#/admin/serie/${this.state.id_serie}/temporadas`}, 2000)
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error'
        })
      })
  }

  render() {
    const { loading, total_episodes, season_number } = this.state

    return (
      <>
        <React.Fragment>
          <Header />
          <Container className='mt--7' fluid>
            <Row>
              <div className='col'>
                <Card className='shadow'>
                  <CardHeader className='border-0'>
                    <h3 className='mb-0'>Temporadas</h3>
                  </CardHeader>
                  <form
                    className='modal-body'
                    onSubmit={this.submitForm}
                    method='post'
                  >
                    <Label>Número da temporada cadastrada</Label>
                    <Input
                      className='addInput_field'
                      value={season_number}
                      name='season_number'
                      placeholder='Número da temporada cadastrada'
                      type='number'
                      onChange={this.handleInputChange}
                    />
                    <Label>Número de episodios</Label>
                    <Input
                      className='addInput_field'
                      value={total_episodes}
                      name='total_episodes'
                      placeholder='Número de episodios'
                      type='number'
                      onChange={this.handleInputChange}
                    />
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      className='notification-modal__button'
                    >
                      {!loading ? (
                        'Salvar'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </React.Fragment>
      </>
    )
  }
}
