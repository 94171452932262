import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input
} from 'reactstrap'
import api from '../../../Services/api'
import Header from '../../../components/Headers/Header.jsx'
import { CircularProgress } from '@material-ui/core'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import SnackBar from '../../../components/Snackbar/index.js'

export default class EditCast extends Component {
  constructor({ match }) {
    super()
    this.state = {
      id_episode: match.params.id,
      name: '',
      number: '',
      editorState: '',
      sinopse: '',
      duration: '',
      poster: '',
      posterUrl: '',
      newPoster: '',
      url: '',
      loading: false,
      snack: false,
      snackVariant: null,
      snackMessage: null
    }
  }

  componentDidMount() {
    this.getCastData()
  }

  getCastData = async () => {
    await api
      .get(`/episodios/${this.state.id_episode}/`)
      .then(resp => {
        const data = resp.data
        if (data.status) {
          const result = data.response.message
          this.setState({
            name: result.title_episode,
            number: result.number_episode,
            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(`${result.mv_sinopse}`)
              )
            ),
            sinopse: result.sinopse_episode,
            duration: result.duration_episode,
            poster: result.poster_episode,
            posterUrl: result.poster_episode,
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleInputChange = e => {
    const { name, value } = e.target

    if (e.target.files) {
      const picture = e.target.files[0]
      this.setState({ newPoster: picture })
    } else {
      this.setState(() => ({ [name]: value }))
    }
  }

  uploadPoster = () => {
    const image = this.state.newPoster
    const data = new FormData()
    data.append('image', image)
    data.append('locale', 'poster')
    api
      .post('imagens/inserir', data)
      .then(resp => {
        const data = resp.data
        if (data.status) {
          this.setState({ posterUrl: data.response.message })
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Erro de uploading',
            snackVariant: 'error'
          })
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error'
        })
      })
  }

  removePoster = image => {
    api
      .put('/imagens/deletar', {
        exclude: image
      })
      .then(resp => {
        this.setState({ newPoster: '' })
        this.setState({ posterUrl: '' })
      })
      .catch(err => {
        console.log(err)
      })
  }

  onEditorStateChange = sinopse => {
    this.setState({
      sinopse
    })
  }

  submitForm = e => {
    e.preventDefault()
    const {
      id_episode,
      name,
      sinopse,
      number,
      duration,
      posterUrl,
      url
    } = this.state
    let decoded = []

    if (typeof sinopse === 'object') {
      decoded = draftToHtml(convertToRaw(sinopse.getCurrentContent()))
    } else {
      decoded = sinopse
    }
    this.setState({ loading: true })
    api
      .post('/episodios/editar', {
        id_episode: id_episode,
        title: name,
        number: number,
        sinopse: decoded,
        duration: duration,
        poster: posterUrl,
        url: url,
      })
      .then(response => {
        if (response.data.status) {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Episodio editado com sucesso!",
            snackVariant: "success"
          });
          setTimeout(() => { window.location.href = `/#/admin/serie/${this.state.id_serie}/temporada/${this.state.id_season}/episodios` }, 2000)
        }
        else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Confira os dados!",
            snackVariant: "warning"
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error'
        })
      })
  }

  render() {
    const { loading } = this.state.loading
    return (
      <>
        <React.Fragment>
          <Header />
          <Container className='mt--7' fluid>
            <Row>
              <div className='col'>
                <Card className='shadow'>
                  <CardHeader className='border-0'>
                    <h3 className='mb-0'>Editar Temporada</h3>
                  </CardHeader>
                  <form
                    className='modal-body'
                    onSubmit={this.submitForm}
                    method='post'
                  >
                    <Label>Nome do episódio</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Nome do filme'
                      name='name'
                      value={this.state.name}
                      type='name'
                      onChange={this.handleInputChange}
                    />
                    <Label>Número do Episódio</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Número do Episódio'
                      value={this.state.number}
                      name='number'
                      type='number'
                      onChange={this.handleInputChange}
                    />
                    <Label>Sinopse</Label>
                    <Editor
                      defaultEditorState={this.state.editorState}
                      placeholder='Sinopse'
                      className='addInput_field_textArea'
                      wrapperClassName='demo-wrapper'
                      editorClassName='demo-editor'
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    <Label>Duração do Episódio</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Duração do Episódio'
                      value={this.state.duration}
                      name='duration'
                      type='time'
                      onChange={this.handleInputChange}
                    />
                    <Label>Url do Episódio</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Nome do Episódio'
                      value={this.state.url}
                      name='url'
                      type='text'
                      onChange={this.handleInputChange}
                    />
                    <Label>Poster</Label>
                    {this.state.posterUrl === '' || this.state.posterUrl === null ? (
                      <div>
                        <img
                          style={{ display: 'none' }}
                          alt=''
                          className='img-thumbnail'
                        />
                        <Button
                          color='primary'
                          className='py-2 px-3 small'
                          style={{ display: 'none' }}
                          size='sm'
                          onClick={() => this.removePoster(this.state.posterUrl)}
                        >
                          Remover
                        </Button>
                      </div>
                    ) : (
                        <div>
                          <img
                            src={this.state.posterUrl}
                            alt=''
                            className='img-thumbnail'
                          />
                          <Button
                            color='primary'
                            className='py-2 px-3 small'
                            size='sm'
                            onClick={() => this.removePoster(this.state.posterUrl)}
                          >
                            Remover
                        </Button>
                        </div>
                      )}
                    <Label
                      for='newPoster'
                      className='py-2 px-3 btn btn-primary btn-sm'
                      size='sm'
                    >
                      Adicionar poster
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className='addInput_field'
                      name='newPoster'
                      type='file'
                      id='newPoster'
                      onChange={this.handleInputChange}
                    />
                    <Button
                      color='primary'
                      className='py-2 px-3'
                      size='sm'
                      onClick={this.uploadPoster}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                    <br />
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      className='notification-modal__button'
                    >
                      {!loading ? (
                        'Salvar'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </React.Fragment>
      </>
    )
  }
}
