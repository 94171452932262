import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input,
} from 'reactstrap';
import {
  EditorState, ContentState, convertFromHTML, convertToRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { debounce } from 'lodash';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import draftToHtml from 'draftjs-to-html';
import { CircularProgress } from '@material-ui/core';
import Header from '../../../components/Headers/Header.jsx';
import api from '../../../Services/api';
import Slugfier from '../../../functions/Slugfier.jsx';
import SnackBar from '../../../components/Snackbar/index.js';

const options = [{ value: 1, label: 'Sim' }, { value: 0, label: 'Não' }];

export default class EditMovies extends Component {
  constructor({ match }) {
    super();
    this.state = {
      serie: null,
      slug: match.params.slug,
      loading: false,
      id_series: '',
      sr_title: '',
      sr_sinopse: null,
      total_seasons: '',
      sr_director: '',
      sr_ranking: '',
      sr_category: '',
      sr_languages: '',
      launch_date: '',
      serie_url: '',
      serie_imdb: '',
      keywords: '',
      trailer: '',
      sr_country: '',
      poster: '',
      banner: '',
      gallery: '',
      commingsoon: '',
      editorState: null,
      newPoster: '',
      posterUrl: '',
      newBanner: '',
      bannerUrl: '',
      newGallery: '',
      galleryUrl: '',
      directors: null,
      director: '',
      directorReq: [],
      indication: '',
      indicationReq: [],
      categories: null,
      category: '',
      categoryReq: [],
      language: '',
      languageReq: [],
      languages: null,
      ranking: null,
      country: '',
      countryReq: [],
      countries: null,
      newCategory: '',
      newLanguage: '',
      newDirector: '',
      newCountry: '',
      newCommingsoon: '',
      emBreve: '',
      snack: false,
      snackVariant: null,
      snackMessage: null,
      slugStatus: false,
      slugLoading: false,
      location_price: 0,
    };
  }

  componentDidMount() {
    this.getMovieData();
    this.directorRequest();
    this.classificationRequest();
    this.categoryRequest();
    this.languageRequest();
    this.countryRequest();
  }

  getMovieData = () => {
    api
      .get(`https://www.vamuvetv.com/vamuvetv-site-api/serie/${this.state.slug}`)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          const result = data.response.message;
          this.setState({
            id_sr: result.id_sr,
            sr_title: result.sr_title,
            sr_sinopse: result.sr_sinopse,
            categories: result.categories,
            directors: result.directors,
            languages: result.languages,
            countries: result.countries,
            ranking: result.ranking,
            total_seasons: result.total_seasons,
            launch_date: result.launch_date,
            serie_url: result.serie_url,
            duration: result.sr_duration,
            sr_ranking: result.sr_ranking,
            sr_languages: result.sr_languages,
            sr_category: result.sr_category,
            release_date: result.release_date,
            serie_imdb: result.serie_imdb,
            trailer: result.sr_trailer,
            poster: result.sr_poster,
            posterUrl: result.sr_poster,
            banner: result.sr_banner,
            bannerUrl: result.sr_banner,
            galleryUrl: result.sr_gallery === null || result.sr_gallery === '' ? [] : result.sr_gallery.split(', '),
            sr_country: result.sr_country,
            keywords: result.sr_keywords,
            commingsoon: result.commingsoon,
            location_price: result.location_price,
            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(`${result.sr_sinopse}`)
              )
            ),
          });

          this.getCommingsoon(result.commingsoon);
          this.getDirectors();
          this.getRanking();
          this.getCategories();
          this.getLanguages();
          this.getCountries();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDirectorList = debounce((value, callback) => {
    if (value === '') return;
    api
      .post('/elenco/pesquisar', { filter: value })
      .then((res) => {
        if (res.data.status) {
          this.setState({ directorReq: res.data.response.message });
          callback(res.data.response.message.map(el => ({ value: el.id_dr, label: `${el.fullname} (${el.slug})` })));
        }
      })
      .catch(error => console.log(error));
  }, 1000);

  getDirectors = () => {
    const { directors } = this.state;
    const parsedDirectors = directors.map(director => ({
      value: director.id_dr,
      label: `${director.fullname} (${director.slug})`,
    }));
    this.setState({ directors: parsedDirectors });
  };

  getCategories = () => {
    const { categories } = this.state;
    const parsedCategories = categories.map(category => ({
      value: category.id_ct,
      label: category.category,
    }));
    this.setState({ categories: parsedCategories });
  };

  getLanguages = () => {
    const { languages } = this.state;
    const parsedLanguages = languages.map(language => ({
      value: language.id_lg,
      label: language.languages,
    }));
    this.setState({ languages: parsedLanguages });
  };

  getCommingsoon = (name) => {
    const newArray = [];
    let value = 0;
    if (
      name === '1' || name === 'Sim'
        ? ((value = 1), (name = 'Sim'))
        : ((value = 0), (name = 'Não'))
    );
    newArray.push({ label: name, value });
    this.setState({ newCommingsoon: value });
    this.setState({ emBreve: newArray });
  };

  getRanking = () => {
    const { ranking } = this.state;
    const parsedRanking = ranking.map(rk => ({
      value: rk.id_rg,
      label: rk.ranking,
    }));
    this.setState({ ranking: parsedRanking });
  };

  getCountries = () => {
    const { countries } = this.state;
    const parsedCountries = countries.map(country => ({
      value: country.id_cy,
      label: country.country,
    }));
    this.setState({ countries: parsedCountries });
  };

  classificationRequest = () => {
    api
      .post('/classificacoes')
      .then((res) => {
        this.setState({ indicationReq: res.data.response.message });
      })
      .catch(error => console.log(error));
    this.setState({
      indicationReq: [...this.state.indicationReq],
    });
  };

  classificationSelect = () => {
    const { indicationReq } = this.state;
    const resultado = indicationReq.map(result => ({
      value: result.id_rg,
      label: result.ranking,
    }));
    return resultado;
  };

  directorRequest = () => {
    api
      .post('/elenco')
      .then((res) => {
        if (res.data.status) {
          this.setState({ directorReq: res.data.response.message });
        }
      })
      .catch(error => console.log(error));
    this.setState({
      directorReq: [...this.state.directorReq],
    });
  };

  directorSelect = () => {
    const { directorReq } = this.state;
    const resultado = directorReq.map(result => ({
      value: result.id_actor,
      label: `${result.fullname} (${result.slug})`,
    }));
    return resultado;
  };

  categoryRequest = () => {
    api
      .post('/categorias')
      .then((res) => {
        this.setState({ categoryReq: res.data.response.message });
      })
      .catch(error => console.log(error));
    this.setState({
      categoryReq: [...this.state.categoryReq],
    });
  };

  categorySelect = () => {
    const { categoryReq } = this.state;
    const resultado = categoryReq.map(result => ({
      value: result.id_ct,
      label: result.category,
    }));
    return resultado;
  };

  languageRequest = () => {
    api
      .post('/idiomas')
      .then((res) => {
        this.setState({ languageReq: res.data.response.message });
      })
      .catch(error => console.log(error));
    this.setState({
      languageReq: [...this.state.languageReq],
    });
  };

  languageSelect = () => {
    const { languageReq } = this.state;
    return languageReq.map(result => ({
      value: result.id_lg,
      label: result.languages,
    }));
  };

  countryRequest = () => {
    api
      .post('/paises')
      .then((res) => {
        this.setState({ countryReq: res.data.response.message });
      })
      .catch(error => console.log(error));
    this.setState({
      countryReq: [...this.state.countryReq],
    });
  };

  countrySelect = () => {
    const { countryReq } = this.state;
    const resultado = countryReq.map(result => ({
      value: result.id_cy,
      label: result.country,
    }));
    return resultado;
  };

  handleChange = (sr_director) => {
    const newdata = [];
    if (sr_director === null) {
      sr_director = 0;
    }
    for (let i = 0; i < sr_director.length; i++) {
      newdata.push({ director: sr_director[i].label });
    }
    this.setState({ newDirector: newdata });
    this.setState({ directors: sr_director });
  };

  handleCommingsoonChange = (commingsoon) => {
    const newdata = [];
    newdata.push({ commingsoon: commingsoon.value });
    this.setState({ newCommingsoon: commingsoon.value });
    this.setState({ emBreve: commingsoon });
  };

  handleRankingChange = (ranking) => {
    this.setState({
      ranking: {
        value: ranking.value,
        label: ranking.label,
      },
    });
  };

  handleCategoryChange = (categories) => {
    const selectedCategories = categories?.map(category => ({
      value: category.value,
      label: category.label,
    }));

    this.setState({ categories: selectedCategories });
  };

  handleLanguageChange = (sr_languages) => {
    const newdata = [];
    if (sr_languages === null) {
      sr_languages = 0;
    }
    for (let i = 0; i < sr_languages.length; i++) {
      newdata.push({ languages: sr_languages[i].label });
    }
    this.setState({ newLanguage: newdata });
    this.setState({ languages: sr_languages });
  };

  handleCountryChange = (mvCountry) => {
    const newdata = [];
    for (let i = 0; i < mvCountry?.length; i++) {
      newdata.push({ country: mvCountry[i].label });
    }
    this.setState({ newCountry: newdata });
    this.setState({ countries: mvCountry });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      const picture = e.target.files[0];
      this.setState({ newPoster: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  handleBannerChange = (e) => {
    const { name, value } = e.target;

    if (e.target.files) {
      const picture = e.target.files[0];
      this.setState({ newBanner: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  handleGalleryChange = (e) => {
    const picture = e.target.files;
    this.setState({ newgallery: picture });
  };

  handleTitleChange = (e) => {
    const { value } = e.target;
    const slug = Slugfier(value);

    this.setState({ sr_title: value });
    this.setState({ slug });
  };

  handleSlugChange = (e) => {
    const { value } = e.target;

    this.setState({ slug: value });
  };

  uploadPoster = () => {
    const image = this.state.newPoster;
    const id = this.state.id_sr;
    const data = new FormData();
    data.append('image', image);
    data.append('locale', 'poster');
    data.append('id', id);
    api
      .post('imagens/inserir', data)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({ posterUrl: data.response.message });
          this.setState({ poster: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Erro de uploading',
            snackVariant: 'error',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error',
        });
      });
  };

  uploadBanner = () => {
    const image = this.state.newBanner;
    const id = this.state.id_sr;
    const data = new FormData();
    data.append('image', image);
    data.append('locale', 'banner');
    data.append('id', id);
    api
      .post('imagens/inserir', data)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({ bannerUrl: data.response.message });
          this.setState({ banner: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Erro de uploading',
            snackVariant: 'error',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error',
        });
      });
  };

  uploadGallery = () => {
    const urls = this.state.galleryUrl;
    const image = this.state.newgallery;
    const id = this.state.id_sr;
    for (let i = 0; i < image.length; i++) {
      const data = new FormData();
      data.append('image', image[i]);
      data.append('locale', 'gallery');
      data.append('id', id);
      api
        .post('imagens/inserir', data)
        .then((resp) => {
          const { data } = resp;
          if (data.status) {
            urls.push(data.response.message);
            this.setState({ galleryUrl: urls });
          } else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Houve algum erro desconhecido no upload',
              snackVariant: 'error',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: 'error',
          });
        });
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    const serie = { ...this.state };
    let decoded = [];
    if (
      !serie.sr_title
      || serie.ranking.length === 0
      || !serie.bannerUrl
      || !serie.posterUrl
      || !serie.slug
    ) {
      this.setState({
        snack: true,
        snackMessage: 'Preencha todos os dados necessários.',
        snackVariant: 'error',
      });
    } else {
      if (typeof serie.sr_sinopse === 'object') {
        decoded = draftToHtml(convertToRaw(serie.sr_sinopse.getCurrentContent()));
      } else {
        decoded = serie.sr_sinopse;
      }

      this.setState({ loading: true });
      api
        .post('/series/editar', {
          id_sr: serie.id_sr,
          slug: serie.slug,
          sr_title: serie.sr_title,
          sr_sinopse: decoded,
          duration: serie.duration,
          ranking: serie.ranking[0] ? serie.ranking[0].value : serie.ranking.value,
          directors: serie.directors?.map(director => director.value).join(',') || '',
          categories: serie.categories?.map(category => category.value).join(',') || '',
          languages: serie.languages?.map(language => language.value).join(',') || '',
          countries: serie.countries?.map(country => country.value).join(',') || '',
          sr_director: this.state.newDirector,
          sr_category: this.state.newCategory,
          sr_languages: this.state.newLanguage,
          sr_country: this.state.newCountry,
          release_date: serie.release_date,
          trailer: serie.trailer,
          keywords: serie.keywords,
          commingsoon: serie.newCommingsoon,
          location_price: serie.location_price,
          poster: serie.posterUrl,
          banner: serie.bannerUrl,
          gallery: serie.galleryUrl !== null ? serie.galleryUrl.join(', ') : serie.galleryUrl,
          total_seasons: serie.total_seasons,
          launch_date: serie.launch_date,
          serie_url: serie.serie_url,
          imdb_url: serie.serie_imdb,
          location_price: serie.location_price,
        })
        .then((response) => {
          if (response.data.status) {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Serie editado com sucesso!',
              snackVariant: 'success',
            });
          } else if (response.data.response.message === 'Invalid slug') {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Slug usado, insira outro!',
              snackVariant: 'warning',
            });
          } else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Confira os dados!',
              snackVariant: 'warning',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: 'error',
          });
        });
    }
  };

  removePoster = (image) => {
    this.setState({ poster: null });
    this.setState({ posterUrl: null });
    api
      .post('imagens/deletar', { exclude: image, locale: 'poster' })
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  removeBanner = (image) => {
    this.setState({ banner: null });
    this.setState({ bannerUrl: null });
    api
      .post('imagens/deletar', { exclude: image, locale: 'banner' })
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  removeGallery(image) {
    const links = this.state.galleryUrl;

    function check(link) {
      return link === image;
    }

    const index = links.findIndex(check);
    links.splice(index, 1);
    this.setState({ galleryUrl: links });
    api
      .post('imagens/deletar', { exclude: image, locale: 'gallery' })
      .then((response) => {
      })
      .catch((error) => {
      });
  }

  onEditorStateChange = (sr_sinopse) => {
    this.setState({
      sr_sinopse,
    });
  };

  getGalleryUrl(string) {
    if (string) {
      return string.map(string => (
        <div className="block__images">
          <img src={string} alt="" className="img__modal" />
          <Button
            color="primary"
            className="py-2 px-3 small button__img"
            size="medium"
            onClick={() => this.removeGallery(string)}
          >
            Remover
          </Button>
        </div>
      ));
    }
    return (<></>);
  }

  render() {
    const { loading } = this.state.loading;
    return (
      <>
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Editar série</h3>
                  </CardHeader>
                  <form
                    className="modal-body"
                    onSubmit={this.submitForm}
                    method="post"
                  >
                    <Label>Nome da Série</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Nome da série"
                      name="title"
                      value={this.state.sr_title}
                      type="name"
                      onChange={this.handleTitleChange}
                    />
                    <Label>Slug</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Slug"
                      name="slug"
                      value={this.state.slug}
                      type="name"
                      onChange={this.handleSlugChange}
                    />
                    <Label>Sinopse</Label>
                    {this.state.editorState && (
                      <Editor
                        defaultEditorState={this.state.editorState}
                        placeholder="Sinopse"
                        className="addInput_field_textArea"
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    )}
                    <Label>Número de Temporadas</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Total de temporadas"
                      value={this.state.total_seasons}
                      name="total_seasons"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Elenco</Label>
                    <AsyncSelect
                      className="addInput_field"
                      placeholder="Elenco"
                      isMulti
                      value={this.state.directors}
                      cacheOptions
                      defaultOptions={this.directorSelect()}
                      loadOptions={this.handleDirectorList}
                      onChange={this.handleChange}
                    />
                    <Label>Classificação</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Classificação"
                      value={this.state.ranking}
                      onChange={this.handleRankingChange}
                      options={this.classificationSelect()}
                    />
                    <Label>Categoria</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Categorias"
                      isMulti
                      value={this.state.categories}
                      onChange={this.handleCategoryChange}
                      options={this.categorySelect()}
                    />
                    <Label>Idiomas</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Idiomas"
                      isMulti
                      value={this.state.languages}
                      onChange={this.handleLanguageChange}
                      options={this.languageSelect()}
                    />
                    <Label>Países</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Países"
                      isMulti
                      value={this.state.countries}
                      onChange={this.handleCountryChange}
                      options={this.countrySelect()}
                    />
                    <Label>Link da serie</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Link da serie"
                      value={this.state.serie_url}
                      name="serieLink"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Link IMDB</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Link IMDB"
                      value={this.state.serie_imdb}
                      name="imdbLink"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Trailer</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Trailer"
                      value={this.state.trailer}
                      name="trailer"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Em Breve</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Em Breve"
                      value={this.state.emBreve}
                      onChange={this.handleCommingsoonChange}
                      options={options}
                    />
                    <Label>Data de Lançamento</Label>
                    <Input
                      className="addInput_field"
                      value={this.state.launch_date}
                      name="launch_date"
                      type="date"
                      onChange={this.handleInputChange}
                    />
                    <Label>Palavras Chaves</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Palavras Chaves"
                      value={this.state.keywords}
                      name="keywords"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Poster</Label>
                    {this.state.posterUrl === ''
                      || this.state.posterUrl === null ? (
                        <div>
                          <img
                            src={this.state.posterUrl}
                            style={{ display: 'none' }}
                            alt=""
                            className="img-thumbnail"
                          />
                          <Button
                            color="primary"
                            className="py-2 px-3 small"
                            style={{ display: 'none' }}
                            size="sm"
                            onClick={() => this.removePoster(this.state.posterUrl)}
                          >
                            Remover
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <img
                            src={this.state.posterUrl}
                            alt=""
                            className="img-thumbnail"
                          />
                          <Button
                            color="primary"
                            className="py-2 px-3 small"
                            size="sm"
                            onClick={() => this.removePoster(this.state.posterUrl)}
                          >
                            Remover
                          </Button>
                        </div>
                      )}
                    <Label
                      for="newPoster"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar poster
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className="addInput_field"
                      name="newPoster"
                      type="file"
                      id="newPoster"
                      onChange={this.handleInputChange}
                    />
                    <br />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadPoster}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                    <br />
                    <Label>Banner</Label>
                    {this.state.banner === '' || this.state.banner === null ? (
                      <div>
                        <img
                          src={this.state.banner}
                          style={{ display: 'none' }}
                          alt=""
                          className="img-thumbnail"
                        />
                        <Button
                          color="primary"
                          className="py-2 px-3 small"
                          style={{ display: 'none' }}
                          size="sm"
                          onClick={() => this.removeBanner(this.state.banner)}
                        >
                          Remover
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={this.state.banner}
                          alt=""
                          className="img-thumbnail"
                        />
                        <Button
                          color="primary"
                          className="py-2 px-3 small"
                          size="sm"
                          onClick={() => this.removeBanner(this.state.banner)}
                        >
                          Remover
                        </Button>
                      </div>
                    )}
                    <Label
                      for="newBanner"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar banner
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className="addInput_field"
                      name="newBanner"
                      type="file"
                      id="newBanner"
                      onChange={this.handleBannerChange}
                    />
                    <br />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadBanner}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                    <br />
                    <Label>Galeria</Label>
                    <div
                      className="div"
                      style={{ display: (!this.state.galleryUrl || this.state.galleryUrl.length === 0) && 'none', paddingBottom: 50 }}
                    >
                      {this.getGalleryUrl(this.state.galleryUrl)}
                    </div>
                    <br />
                    <Label
                      for="newGallery"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar galeria de imagens
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className="addInput_field"
                      name="newGallery[]"
                      type="file"
                      multiple
                      id="newGallery"
                      onChange={this.handleGalleryChange}
                    />
                    <br />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadGallery}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                    <br />
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className="notification-modal__button"
                    >
                      Salvar
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </>
      </>
    );
  }
}
