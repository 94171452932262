import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  CardBody,
  Input,
  Table,
  Col,
} from 'reactstrap';
import { Modal } from '@material-ui/core';
import Header from '../../../components/Headers/Header';
import SuccessModal from '../../../components/SuccessModal';
import api from '../../../Services/api';
import EmailModal from '../../../components/EmailModal';
import SnackBar from '../../../components/Snackbar';

class EditUser extends React.Component {
  constructor({ match }) {
    super();
    this.state = {
      id: match.params.id,
      successModal: false,
      alertModal: false,
      removeModal: false,
      loading: false,
      modal: false,
      redirect: false,
      search: null,
      reqSuccess: false,
      result: [],
      active: null,
      data: [],
      emailModal: false,
      clientModal: false,
      modal: [],
      snack: false,
      snackVariant: null,
      snackMessage: null,
    };
  }

  componentWillMount() {
    this.getUserData();
  }

  getUserData = () => {
    this.setState({ loading: true });
    api
      .get(`/usuarios/${this.state.id}/`)
      .then((resp) => {
        if (resp.data.status) {
          this.setState({ loading: false });
          this.setState({ data: resp.data.response.message });
        } else {
          this.setState({ loading: false });
          this.setState({ data: this.state.result });
        }
      })
      .catch((error) => {
        this.setState({ loading: true });
        console.log(error);
      });
  };

  buscarId(id) {
    this.setState({
      id,
    });
    return id;
  }

  setActive(status) {
    if (Number(status) === 1) {
      this.setState({ active: 0 });
    } else {
      this.setState({ active: 1 });
    }
  }

  toggleModal = (modal) => {
    this.setState(state => ({
      [modal]: !state[modal],
    }));
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(oldState => ({ data: { ...oldState.data, [name]: value } }));
  };

  confirmExclude = (e) => {
    e.preventDefault();
    api
      .post('/usuarios/moderar', {
        id: this.state.id,
        active: this.state.active,
      })
      .then((response) => {
        setTimeout(window.location.reload(), 1000);
      })
      .catch((error) => {});
  };

  submitForm = (e) => {
    e.preventDefault();
    const { data } = this.state;
    if (
      !data.fullname
      || !data.username
      || !data.email
      || !data.cpf
      || !data.country
      || !data.city
      || !data.neighborhood
      || !data.cep
      || !data.adress
    ) {
      this.setState({
        snack: true,
        snackMessage: 'Preencha todos os dados necessários.',
        snackVariant: 'error',
      });
    } else {
      this.setState({ loading: true });

      api
        .post('/usuarios/editar', { ...data })
        .then((response) => {
          if (response.data.status) {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Usuário editado com sucesso!',
              snackVariant: 'success',
            });
            setTimeout(() => { window.location.href = '/#/admin/usuarios'; }, 2000);
          } else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Algo deu errado, confira os dados',
              snackVariant: 'warning',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: 'error',
          });
        });
    }
  };

  render() {
    const { successModal, data, removeModal } = this.state;
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Col className="order-xl-1">
                <Card className="bg-secondary shadow">
                  <form
                    className="modal-body"
                    onSubmit={this.submitForm}
                    method="post"
                  >
                    <CardBody>
                      <h6 className="heading-small text-muted mb-4">
                        Informações do usuário
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-fullname"
                              >
                                Nome completo
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="fullname"
                                id="input-fullname"
                                placeholder="fullname"
                                value={data.fullname}
                                type="text"
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Nome de usuário
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="username"
                                id="input-username"
                                placeholder="Username"
                                value={data.username}
                                type="text"
                                onChange={this.handleInputChange}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="email"
                                id="input-email"
                                placeholder="First name"
                                value={data.email}
                                type="email"
                                onChange={this.handleInputChange}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-cpf"
                              >
                                CPF
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="cpf"
                                id="input-fcpf"
                                placeholder="CPF"
                                value={
                                  data.cpf !== undefined
                                    ? `${data.cpf.slice(0, 3)}.${data.cpf.slice(
                                      3,
                                      6
                                    )}.${data.cpf.slice(
                                      6,
                                      9
                                    )}-${data.cpf.slice(9, 11)}`
                                    : ''
                                }
                                type="text"
                                onChange={this.handleInputChange}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Tipo de usuário
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-first-name"
                                placeholder="First name"
                                value={
                                  data.type_account === 'Free'
                                    ? 'Gratuito'
                                    : 'Membro'
                                }
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Assinante desde:
                              </label>
                              {data.type_account === 'Member' ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-first-name"
                                  placeholder="First name"
                                  value={new Date(data.expire_signature)
                                    .toISOString()
                                    .substr(0, 10)
                                    .split('-')
                                    .reverse()
                                    .join('/')}
                                  type="text"
                                  disabled
                                />
                              ) : (
                                <Input
                                  className="form-control-alternative"
                                  id="input-first-name"
                                  placeholder="First name"
                                  value="Usuário não é assinante"
                                  type="text"
                                  disabled
                                />
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Situação
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-first-name"
                                placeholder="Situação"
                                value={Number(data.active) === 1 ? 'Ativo' : 'Inativo'}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Endereço
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                Estado
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="country"
                                id="input-country"
                                placeholder="Estado"
                                value={data.country}
                                type="string"
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                              >
                                Cidade
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="city"
                                id="input-city"
                                placeholder="Cidade"
                                value={data.city}
                                type="text"
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-neighborhood"
                              >
                                Bairro
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="neighborhood"
                                id="input-neighborhood"
                                placeholder="Bairro"
                                value={data.neighborhood}
                                type="text"
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-postal-code"
                              >
                                CEP
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="cep"
                                id="input-postal-code"
                                placeholder="Postal code"
                                value={data.cep}
                                type="number"
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Endereço
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="adress"
                                id="input-address"
                                placeholder="Home Address"
                                value={data.adress}
                                type="text"
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                    <CardFooter className="py-4">
                      <nav aria-label="..." />
                      <Button
                        color="primary"
                        className="py-2 px-3"
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            modal: { fullname: data.fullname, email: data.email },
                            emailModal: true,
                          });
                        }}
                      >
                        Enviar email
                      </Button>
                      <Button
                        color="primary"
                        className="py-2 px-3"
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleModal('removeModal');
                          this.buscarId(data.id);
                          this.setActive(data.active);
                        }}
                      >
                        Moderar
                      </Button>
                      <Button
                        color="primary"
                        className="py-2 px-3"
                        size="sm"
                        type="submit"
                      >
                        Salvar
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </Col>
            </div>
          </Row>
          {removeModal && (
            <Modal
              open
              onClose={() => this.toggleModal('removeModal')}
              onSuccess={() => {
                this.toggleModal('removeModal');
                this.toggleModal('successModal');
              }}
              onError={() => this.toggleModal('alertModal')}
            >
              <div className="alert__content">
                <h1 className="alert__title">Aviso</h1>
                <h2 className="alert__subheader">Deseja realmente alterar?</h2>
                <button type="button" className="alert__button" onClick={this.confirmExclude}>
                  Confirmar
                </button>
              </div>
            </Modal>
          )}

          <EmailModal
            data={this.state.modal}
            open={this.state.emailModal}
            onClose={() => this.setState({ emailModal: false })}
          />
          <SuccessModal
            show={successModal}
            onClick={() => this.toggleModal('successModal')}
          />

          <SnackBar
            isOpen={this.state.snack}
            isVariant={this.state.snackVariant}
            isMessage={this.state.snackMessage}
            isClose={() => this.setState({ snack: false })}
          />
        </Container>
      </>
    );
  }
}

export default EditUser;
