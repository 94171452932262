import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input,
} from 'reactstrap';
import {
  EditorState, ContentState, convertFromHTML, convertToRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import draftToHtml from 'draftjs-to-html';
import { CircularProgress } from '@material-ui/core';
import { debounce } from 'lodash';
import Header from '../../../components/Headers/Header';
import api, { siteApi } from '../../../Services/api';
import Slugfier from '../../../functions/Slugfier';
import SnackBar from '../../../components/Snackbar/index';

const options = [{ value: 1, label: 'Sim' }, { value: 0, label: 'Não' }];

export default class EditMovies extends Component {
  constructor({ match }) {
    super();
    this.state = {
      film: null,
      slug: match.params.slug,
      loading: false,
      id_mv: null,
      title: null,
      sinopse: null,
      duration: null,
      mv_ranking: null,
      mv_director: null,
      mv_category: null,
      mv_languages: null,
      mv_country: null,
      release_date: null,
      movie_url: null,
      movie_imdb: null,
      trailer: null,
      keywords: null,
      highlighted: null,
      commingsoon: null,
      location_price: null,
      poster: null,
      banner: null,
      gallery: null,
      editorState: null,
      newPoster: null,
      posterUrl: null,
      newBanner: null,
      bannerUrl: null,
      newgallery: null,
      galleryUrl: null,
      director: null,
      directorReq: [],
      indication: null,
      indicationReq: [],
      directors: [],
      classification: null,
      categories: null,
      category: null,
      categoryReq: [],
      language: '',
      languageReq: [],
      languages: '',
      country: '',
      countryReq: [],
      countries: '',
      newCommingsoon: '',
      newHighlighted: '',
      emBreve: '',
      emDestaque: '',
      slugStatus: false,
      slugLoading: false,

    };
  }

  componentDidMount() {
    this.getMovieData();
    this.directorRequest();
    this.classificationRequest();
    this.categoryRequest();
    this.languageRequest();
    this.countryRequest();
  }

  getMovieData = () => {
    const { slug } = this.state;
    siteApi
      .get(`filme/${slug}`)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          const result = data.response.message;
          this.setState({
            film: data.response.message,
            id_mv: result.id_mv,
            slug: result.slug,
            title: result.mv_title,
            sinopse: result.mv_sinopse,
            duration: result.mv_duration,
            ranking: result.ranking,
            languages: result.languages,
            categories: result.categories,
            directors: result.directors,
            release_date: result.release_date,
            movie_url: result.movie_url,
            movie_imdb: result.movie_imdb,
            trailer: result.mv_trailer,
            poster: result.mv_poster,
            posterUrl: result.mv_poster,
            banner: result.mv_banner,
            bannerUrl: result.mv_banner,
            galleryUrl: result.mv_gallery === null || result.mv_gallery === '' ? [] : result.mv_gallery.split(', '),
            countries: result.countries,
            keywords: result.mv_keywords,
            highlighted: result.highlighted,
            commingsoon: result.commingsoon,
            location_price: result.location_price,
            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(`${result.mv_sinopse}`)
              )
            ),
          });

          this.getCommingsoon(result.commingsoon);
          this.getRanking();
          this.getCategories();
          this.getDirectors();
          this.getLanguages();
          this.getCountries();
          this.getHighlighted(result.highlighted);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCommingsoon = (name) => {
    const newArray = [];
    let value = 0;
    if (name === '1' || name === 'Sim') {
      name = 'Sim';
      value = 1;
    } else {
      name = 'Não';
      value = 0;
    }
    newArray.push({ label: name, value });
    this.setState({ newCommingsoon: value });
    this.setState({ emBreve: newArray });
  };

  getHighlighted = (name) => {
    const newArray = [];
    let value = 0;
    if (name === '1' || name === 'Sim') {
      name = 'Sim';
      value = 1;
    } else {
      name = 'Não';
      value = 0;
    }
    newArray.push({ label: name, value });
    this.setState({ newHighlighted: value });
    this.setState({ emDestaque: newArray });
  };

  getRanking = () => {
    const { ranking } = this.state;
    const parsedRanking = ranking.map(rk => ({
      value: rk.id_rg,
      label: rk.ranking,
    }));
    this.setState({ ranking: parsedRanking });
  };

  getCategories = () => {
    const { categories } = this.state;
    const parsedCategories = categories.map(category => ({
      value: category.id_ct,
      label: category.category,
    }));
    this.setState({ categories: parsedCategories });
  };

  getLanguages = () => {
    const { languages } = this.state;
    const parsedLanguages = languages.map(language => ({
      value: language.id_lg,
      label: language.languages,
    }));
    this.setState({ languages: parsedLanguages });
  };

  getCountries = () => {
    const { countries } = this.state;
    const parsedCountries = countries.map(country => ({
      value: country.id_cy,
      label: country.country,
    }));
    this.setState({ countries: parsedCountries });
  };

  getDirectors = () => {
    const { directors } = this.state;
    const parsedDirectors = directors.map(director => ({
      value: director.id_dr,
      label: `${director.fullname} (${director.slug})`,
    }));
    this.setState({ directors: parsedDirectors });
  };

  directorSelect = () => {
    const { directorReq } = this.state;
    const resultado = directorReq.map(result => ({
      value: result.id_actor,
      label: `${result.fullname} (${result.slug})`,
    }));
    return resultado;
  };

  classificationRequest = () => {
    api
      .post('/classificacoes')
      .then((res) => {
        this.setState({ indicationReq: res.data.response.message });
      })
      .catch(error => console.log(error));
    this.setState({
      indicationReq: [...this.state.indicationReq],
    });
  };

  classificationSelect = () => {
    const { indicationReq } = this.state;
    const resultado = indicationReq.map(result => ({
      value: result.id_rg,
      label: result.ranking,
    }));
    return resultado;
  };

  directorRequest = () => {
    api
      .post('/elenco')
      .then((res) => {
        if (res.data.status) {
          this.setState({ directorReq: res.data.response.message });
        }
      })
      .catch(error => console.log(error));
  };

  handleDirectorList = debounce((value, callback) => {
    if (value === '') return;
    api
      .post('/elenco/pesquisar', { filter: value })
      .then((res) => {
        if (res.data.status) {
          this.setState({ directorReq: res.data.response.message });
          callback(res.data.response.message.map(el => ({ value: el.id_dr, label: `${el.fullname} (${el.slug})` })));
        }
      })
      .catch(error => console.log(error));
  }, 1000);

  categoryRequest = () => {
    api
      .post('/categorias')
      .then((res) => {
        this.setState({ categoryReq: res.data.response.message });
      })
      .catch(error => console.log(error));
    this.setState({
      categoryReq: [...this.state.categoryReq],
    });
  };

  categorySelect = () => {
    const { categoryReq } = this.state;
    const resultado = categoryReq.map(result => ({
      value: result.id_ct,
      label: result.category,
    }));
    return resultado;
  };

  languageRequest = () => {
    api
      .post('/idiomas')
      .then((res) => {
        this.setState({ languageReq: res.data.response.message });
      })
      .catch(error => console.log(error));
    this.setState({
      languageReq: [...this.state.languageReq],
    });
  };

  languageSelect = () => {
    const { languageReq } = this.state;
    return languageReq.map(result => ({
      value: result.id_lg,
      label: result.languages,
    }));
  };

  countryRequest = () => {
    api
      .post('/paises')
      .then((res) => {
        this.setState({ countryReq: res.data.response.message });
      })
      .catch(error => console.log(error));
    this.setState({
      countryReq: [...this.state.countryReq],
    });
  };

  countrySelect = () => {
    const { countryReq } = this.state;
    const resultado = countryReq.map(result => ({
      value: result.id_cy,
      label: result.country,
    }));
    return resultado;
  };

  handleChange = (directors) => {
    const selectedDirectors = directors?.map(director => ({
      value: director.value,
      label: director.label,
    }));

    this.setState({ directors: selectedDirectors });
  };

  handleHighlightedChange = (highlighted) => {
    const newdata = [];
    newdata.push({ highlighted: highlighted.value });
    this.setState({ newHighlighted: highlighted.value });
    this.setState({ emDestaque: highlighted });
  };

  handleCommingsoonChange = (commingsoon) => {
    const newdata = [];
    newdata.push({ commingsoon: commingsoon.value });
    this.setState({ newCommingsoon: commingsoon.value });
    this.setState({ emBreve: commingsoon });
  };

  handleRankingChange = (ranking) => {
    this.setState({
      ranking: {
        value: ranking.value,
        label: ranking.label,
      },
    });
  };

  handleCategoryChange = (categories) => {
    const selectedCategories = categories?.map(category => ({
      value: category.value,
      label: category.label,
    }));

    this.setState({ categories: selectedCategories });
  };

  handleLanguageChange = (languages) => {
    const selectedLanguages = languages?.map(language => ({
      value: language.value,
      label: language.label,
    }));

    this.setState({ languages: selectedLanguages });
  };

  handleCountryChange = (mvCountry) => {
    const newdata = [];
    for (let i = 0; i < mvCountry?.length; i++) {
      newdata.push({ country: mvCountry[i].label });
    }
    this.setState({ newCountry: newdata });
    this.setState({ countries: mvCountry });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    if (e.target.files) {
      const picture = e.target.files[0];
      this.setState({ newPoster: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  handleBannerChange = (e) => {
    const { name, value } = e.target;

    if (e.target.files) {
      const picture = e.target.files[0];
      this.setState({ newBanner: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  handlegalleryChange = (e) => {
    const picture = e.target.files;
    this.setState({ newgallery: picture });
  };

  handleTitleChange = (e) => {
    const { value } = e.target;
    const slug = Slugfier(value);

    this.setState({ title: value });
    this.setState({ slug });
  };

  handleSlugChange = (e) => {
    const { value } = e.target;

    this.setState({ slug: value });
  };

  uploadPoster = () => {
    const image = this.state.newPoster;
    const data = new FormData();
    data.append('image', image);
    data.append('locale', 'poster');
    api
      .post('imagens/inserir', data)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({ posterUrl: data.response.message });
          this.setState({ poster: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Erro de uploading',
            snackVariant: 'error',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error',
        });
      });
  };

  uploadBanner = () => {
    const image = this.state.newBanner;
    const data = new FormData();
    data.append('image', image);
    data.append('locale', 'banner');
    api
      .post('imagens/inserir', data)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({ bannerUrl: data.response.message });
          this.setState({ banner: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Erro de uploading',
            snackVariant: 'error',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error',
        });
      });
  };

  uploadGallery = () => {
    const urls = this.state.galleryUrl;
    const image = this.state.newgallery;
    for (let i = 0; i < image.length; i++) {
      const data = new FormData();
      data.append('image', image[i]);
      data.append('locale', 'gallery');
      api
        .post('imagens/inserir', data)
        .then((resp) => {
          const { data } = resp;
          if (data.status) {
            urls.push(data.response.message);
            this.setState({ galleryUrl: urls });
          } else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Houve algum erro desconhecido no upload',
              snackVariant: 'error',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: 'error',
          });
        });
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    const film = { ...this.state };
    let decoded = [];
    if (
      !film.title
      || film.ranking.length === 0
      || !film.bannerUrl
      || !film.posterUrl
      || !film.slug
    ) {
      this.setState({
        snack: true,
        snackMessage: 'Preencha todos os dados necessários.',
        snackVariant: 'error',
      });
    } else {
      if (typeof film.sinopse === 'object') {
        decoded = draftToHtml(convertToRaw(film.sinopse.getCurrentContent()));
      } else {
        decoded = film.sinopse;
      }

      this.setState({ loading: true });
      api
        .post('/filmes/editar', {
          id_mv: film.id_mv,
          title: film.title,
          sinopse: decoded,
          duration: film.duration,
          ranking: film.ranking[0].value,
          directors: film.directors?.map(director => director.value).join(',') || '',
          categories: film.categories?.map(category => category.value).join(',') || '',
          languages: film.languages?.map(language => language.value).join(',') || '',
          countries: film.countries?.map(country => country.value).join(',') || '',
          release_date: film.release_date,
          movie_url: film.movie_url,
          movie_imdb: film.movie_imdb,
          trailer: film.trailer,
          keywords: film.keywords,
          highlighted: film.newHighlighted,
          commingsoon: film.newCommingsoon,
          location_price: film.location_price,
          poster: film.posterUrl,
          banner: film.bannerUrl,
          gallery: film.galleryUrl !== null ? film.galleryUrl.join(', ') : film.galleryUrl,
          slug: film.slug,
        })
        .then((response) => {
          if (response.data.status) {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Filme editado com sucesso, aguarde o painel atualizar...',
              snackVariant: 'success',
            });
          } else if (response.data.response.message === 'Invalid slug') {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Slug usado, insira outro!',
              snackVariant: 'warning',
            });
          } else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Confira os dados!',
              snackVariant: 'warning',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: 'error',
          });
        });
    }
  };

  removePoster = (image) => {
    this.setState({ poster: null });
    this.setState({ posterUrl: null });
    api
      .post('imagens/deletar', { exclude: image, locale: 'poster' })
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  removeBanner = (image) => {
    this.setState({ banner: null });
    this.setState({ bannerUrl: null });
    api
      .post('imagens/deletar', { exclude: image, locale: 'banner' })
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  removeGallery = (image) => {
    const links = this.state.galleryUrl;

    function check(link) {
      return link === image;
    }

    const index = links.findIndex(check);
    links.splice(index, 1);
    this.setState({ galleryUrl: links });
    api
      .post('imagens/deletar', { exclude: image, locale: 'gallery' })
      .then((response) => {
      })
      .catch((error) => {
      });
  }

  getGalleryUrl = (string) => {
    if (string) {
      return string.map(string => (
        <div className="block__images">
          <img src={string} alt="" className="img__modal" />
          <Button
            color="primary"
            className="py-2 px-3 small button__img"
            size="medium"
            onClick={() => this.removeGallery(string)}
          >
            Remover
          </Button>
        </div>
      ));
    }
    return (<></>);
  }

  onEditorStateChange = (sinopse) => {
    this.setState({
      sinopse,
    });
  };

  render() {
    const { loading } = this.state.loading;
    return (
      <>
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Editar Filme</h3>
                  </CardHeader>
                  <form
                    className="modal-body"
                    onSubmit={this.submitForm}
                    method="post"
                  >
                    <Label>Nome do filme</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Nome do filme"
                      name="title"
                      value={this.state.title}
                      type="name"
                      onChange={this.handleTitleChange}
                    />
                    <Label>Slug</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Slug"
                      name="slug"
                      value={this.state.slug}
                      type="name"
                      onChange={this.handleSlugChange}
                    />
                    <Label>Sinopse</Label>
                    {this.state.editorState && (
                      <Editor
                        defaultEditorState={this.state.editorState}
                        placeholder="Sinopse"
                        className="addInput_field_textArea"
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    )}
                    <Label>Duração(min)</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Duração"
                      value={this.state.duration}
                      name="duration"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Classificação</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Classificação"
                      value={this.state.ranking}
                      onChange={this.handleRankingChange}
                      options={this.classificationSelect()}
                    />
                    <Label>Elenco</Label>
                    <AsyncSelect
                      className="addInput_field"
                      placeholder="Elenco"
                      isMulti
                      value={this.state.directors}
                      cacheOptions
                      defaultOptions={this.directorSelect()}
                      loadOptions={this.handleDirectorList}
                      onChange={this.handleChange}
                    />
                    <Label>Categorias</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Categorias"
                      isMulti
                      value={this.state.categories}
                      onChange={this.handleCategoryChange}
                      options={this.categorySelect()}
                    />
                    <Label>Idiomas</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Idiomas"
                      isMulti
                      value={this.state.languages}
                      onChange={this.handleLanguageChange}
                      options={this.languageSelect()}
                    />
                    <Label>Paises</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Paises"
                      isMulti
                      value={this.state.countries}
                      onChange={this.handleCountryChange}
                      options={this.countrySelect()}
                    />
                    <Label>Lançamento</Label>
                    <Input
                      className="addInput_field"
                      value={this.state.release_date}
                      name="release_date"
                      type="date"
                      onChange={this.handleInputChange}
                    />
                    <Label>Link do Filme</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Link do Filme"
                      value={this.state.movie_url}
                      name="movie_url"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Link do IMDB</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Link do IMDB"
                      value={this.state.movie_imdb}
                      name="movie_imdb"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Trailer</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Trailer"
                      value={this.state.trailer}
                      name="trailer"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Poster</Label>
                    {this.state.poster === '' || this.state.poster === null ? (
                      <div>
                        <img
                          style={{ display: 'none' }}
                          alt=""
                          className="img-thumbnail"
                        />
                        <Button
                          color="primary"
                          className="py-2 px-3 small"
                          style={{ display: 'none' }}
                          size="sm"
                          onClick={() => this.removePoster(this.state.poster)}
                        >
                          Remover
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={this.state.poster}
                          alt=""
                          className="img-thumbnail"
                        />
                        <Button
                          color="primary"
                          className="py-2 px-3 small"
                          size="sm"
                          onClick={() => this.removePoster(this.state.poster)}
                        >
                          Remover
                        </Button>
                      </div>
                    )}
                    <Label
                      for="newPoster"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar poster
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className="addInput_field"
                      name="newPoster"
                      type="file"
                      id="newPoster"
                      onChange={this.handleInputChange}
                    />
                    <br />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadPoster}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                    <br />
                    <Label>Banner</Label>
                    {this.state.banner === '' || this.state.banner === null ? (
                      <div>
                        <img
                          src={this.state.banner}
                          style={{ display: 'none' }}
                          alt=""
                          className="img-thumbnail"
                        />
                        <Button
                          color="primary"
                          className="py-2 px-3 small"
                          style={{ display: 'none' }}
                          size="sm"
                          onClick={() => this.removeBanner(this.state.banner)}
                        >
                          Remover
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={this.state.banner}
                          alt=""
                          className="img-thumbnail"
                        />
                        <Button
                          color="primary"
                          className="py-2 px-3 small"
                          size="sm"
                          onClick={() => this.removeBanner(this.state.banner)}
                        >
                          Remover
                        </Button>
                      </div>
                    )}
                    <Label
                      for="newBanner"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar banner
                    </Label>
                    <br />
                    <Input
                      style={{ display: 'none' }}
                      className="addInput_field"
                      name="newBanner"
                      type="file"
                      id="newBanner"
                      onChange={this.handleBannerChange}
                    />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadBanner}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                    <br />
                    <Label>Galeria</Label>
                    <div
                      className="div"
                      style={{ display: (!this.state.galleryUrl || this.state.galleryUrl.length === 0) && 'none', paddingBottom: 50 }}
                    >
                      {this.getGalleryUrl(this.state.galleryUrl)}
                    </div>
                    <br />
                    <Label
                      for="newgallery"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar galeria de imagens
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className="addInput_field"
                      name="newgallery[]"
                      type="file"
                      multiple
                      id="newgallery"
                      onChange={this.handlegalleryChange}
                    />
                    <br />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadGallery}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                    <br />
                    <Label>Palavras Chaves</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Palavras Chaves"
                      value={this.state.keywords}
                      name="keywords"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Em Destaque</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Em Destaque"
                      value={this.state.emDestaque}
                      onChange={this.handleHighlightedChange}
                      options={options}
                    />
                    <Label>Em Breve</Label>
                    <Select
                      className="addInput_field"
                      placeholder="Em Breve"
                      value={this.state.emBreve}
                      onChange={this.handleCommingsoonChange}
                      options={options}
                    />
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className="notification-modal__button"
                    >
                      {!loading ? (
                        'Salvar'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </>
      </>
    );
  }
}
