import Login from './views/pages/Login';
import Series from './views/pages/Series/Series';
import News from './views/pages/News/News';
import AddNews from './views/pages/News/AddNews';
import EditNews from './views/pages/News/EditNews';
import Movies from './views/pages/Movies/Movies';
import Cast from './views/pages/Cast/Cast';
import AddCast from './views/pages/Cast/AddCast';
import EditCast from './views/pages/Cast/EditCast';
import Programming from './views/pages/Programmation/Programmation';
import AddProgram from './views/pages/Programmation/AddProgram';
import EditProgram from './views/pages/Programmation/EditProgram';
import AddMovie from './views/pages/Movies/AddMovie';
import EditMovie from './views/pages/Movies/EditMovie';
import AddSerie from './views/pages/Series/AddSerie';
import EditSerie from './views/pages/Series/EditSerie';
import AddSeason from './views/pages/Season/AddSeason';
import EditSeason from './views/pages/Season/EditSeason';
import AddEpisode from './views/pages/Episode/AddEpisode';
import EditEpisode from './views/pages/Episode/EditEpisode';
import Historic from './views/pages/Historic/Historic';
import Users from './views/pages/Users/Users';
import User from './views/pages/Users/User';
import EditUser from './views/pages/Users/EditUser';
import Seasons from './views/pages/Season/Seasons';
import Episodes from './views/pages/Episode/Episodes';
import Settings from './views/pages/Settings/Settings';

const routes = [
  {
    path: '/filmes',
    name: 'Filmes',
    icon: 'ni ni-tv-2 text-orange',
    component: Movies,
    layout: '/admin',
  },
  {
    path: '/filmes/adicionar',
    component: AddMovie,
    layout: '/admin',
  },
  {
    path: '/series/adicionar',
    component: AddSerie,
    layout: '/admin',
  },
  {
    path: '/filmes/editar/:slug',
    component: EditMovie,
    layout: '/admin',
  },
  {
    path: '/series/editar/:slug',
    component: EditSerie,
    layout: '/admin',
  },
  {
    path: '/series',
    name: 'Séries',
    icon: 'ni ni-tv-2 text-orange',
    component: Series,
    layout: '/admin',
  },
  {
    path: '/elenco',
    name: 'Atores e Diretores',
    icon: 'ni ni-hat-3 text-orange',
    component: Cast,
    layout: '/admin',
  },
  {
    path: '/elenco/adicionar',
    component: AddCast,
    layout: '/admin',
  },
  {
    path: '/elenco/editar/:slug',
    component: EditCast,
    layout: '/admin',
  },
  {
    path: '/programacao',
    name: 'Programação',
    icon: 'ni ni-calendar-grid-58 text-orange',
    component: Programming,
    layout: '/admin',
  },
  {
    path: '/programacao/adicionar',
    component: AddProgram,
    layout: '/admin',
  },
  {
    path: '/programacao/editar/:id',
    component: EditProgram,
    layout: '/admin',
  },
  {
    path: '/noticias',
    name: 'Notícias',
    icon: 'ni ni-book-bookmark text-orange',
    component: News,
    layout: '/admin',
  },
  {
    path: '/noticias/adicionar',
    component: AddNews,
    layout: '/admin',
  },
  {
    path: '/noticias/editar/:id',
    component: EditNews,
    layout: '/admin',
  },
  {
    path: '/serie/:id/temporadas',
    component: Seasons,
    layout: '/admin',
  },
  {
    path: '/serie/:id_sr/temporada/:id_temp/episodios',
    component: Episodes,
    layout: '/admin',
  },
  {
    path: '/temporada/adicionar/:id',
    component: AddSeason,
    layout: '/admin',
  },
  {
    path: '/temporada/editar/:id',
    component: EditSeason,
    layout: '/admin',
  },
  {
    path: '/episodio/adicionar/:serie/:season',
    component: AddEpisode,
    layout: '/admin',
  },
  {
    path: '/episodio/editar/:id',
    component: EditEpisode,
    layout: '/admin',
  },
  {
    path: '/registros',
    name: 'Registros',
    icon: 'ni ni-bullet-list-67 text-orange',
    component: Historic,
    layout: '/admin',
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    icon: 'ni ni-single-02 text-orange',
    component: Users,
    layout: '/admin',
  },
  {
    path: '/usuarios/:id',
    component: User,
    layout: '/admin',
  },
  {
    path: '/usuarios/editar/:id',
    component: EditUser,
    layout: '/admin',
  },
  // {
  //   path: '/configuracoes',
  //   name: 'Configurações',
  //   icon: 'ni ni-settings-gear-65 text-orange',
  //   component: Settings,
  //   layout: '/admin',
  // },
  {
    path: '/login',
    icon: '',
    component: Login,
    layout: '/auth',
  },
];
export default routes;
