import React from "react";
import { CircularProgress } from "@material-ui/core";
import Header from "../../../components/Headers/Header.jsx";
import api from "../../../Services/api";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input
} from "reactstrap";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SnackBar from "../../../components/Snackbar/index.js";

export default class AddActor extends React.Component {
  state = {
    name: "",
    biograpy: EditorState.createEmpty(),
    photo: "",
    photoUrl: null,
    date: null,
    birthplace: null,
    deadplace: null,
    gallery: "",
    galleryUrl: null,
    dead_date: null,
    loading: false,
    requests: "",
    snack: false,
    snackVariant: null,
    snackMessage: null
  };

  handleInputChange = e => {
    const { name, value } = e.target;

    if (e.target.files) {
      const picture = e.target.files[0];
      this.setState({ photo: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  handleGalleryChange = e => {
    const { name, value } = e.target;

    if (e.target.files) {
      const picture = e.target.files;
      this.setState({ gallery: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  uploadPoster = () => {
    const image = this.state.photo;
    const data = new FormData();
    data.append("image", image);
    data.append("locale", "poster");
    api
      .post("imagens/inserir", data)
      .then(resp => {
        const data = resp.data;
        if (data.status) {
          this.setState({ photoUrl: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Erro de uploading",
            snackVariant: "error"
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: "error"
        });
      });
  };

  uploadGallery = () => {
    let urls = [];
    const image = this.state.gallery;
    for (let i = 0; i < image.length; i++) {
      const data = new FormData();
      data.append("image", image[i]);
      data.append("locale", "gallery");
      api
        .post("imagens/inserir", data)
        .then(resp => {
          const data = resp.data;
          if (data.status) {
            urls.push(data.response.message);
            this.setState({ galleryUrl: urls });
          } else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: "Houve algum erro desconhecido no upload",
              snackVariant: "error"
            });
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: "error"
          });
        });
    }
  };

  submitForm = e => {
    e.preventDefault();
    const {
      name,
      biograpy,
      date,
      birthplace,
      dead_date,
      deadplace,
      photoUrl,
      galleryUrl
    } = this.state;

    if (!name || !biograpy) {
      this.setState({
        snack: true,
        snackMessage: "Preencha todos os dados necessários.",
        snackVariant: "error"
      });
    } else {
      this.setState({ loading: true });
      const decoded = draftToHtml(convertToRaw(biograpy.getCurrentContent()));

      api
        .post("/elenco/inserir", {
          name_director: name,
          biograpy: decoded,
          brt_date: date,
          dead_date: dead_date,
          birthplace: birthplace,
          deadplace: deadplace,
          picture: photoUrl,
          gallery: galleryUrl !== null ? galleryUrl.join(", ") : galleryUrl
        })
        .then(response => {
          if(response.data.status){
            this.setState({
            loading: false,
            snack: true,
            snackMessage: "Integrante adicionado com sucesso!",
            snackVariant: "success"
            });
            setTimeout(window.location.href = '/#/admin/elenco', 2000)
          }else{
            this.setState({
            loading: false,
            snack: true,
            snackMessage: "Confira os dados!",
            snackVariant: "warning"
            });
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: "error"
          });
        });
    }
  };

  onEditorStateChange = biograpy => {
    this.setState({
      biograpy
    });
  };

  getGalleryUrl(string) {
    if (string) {
      return string.map(string => (
        <div className="block__images">
          <img src={string} alt="" className="img__modal" />
          <Button
            color="primary"
            className="py-2 px-3 small button__img"
            size="medium"
            onClick={() => this.removeGallery(string)}
          >
            Remover
          </Button>
        </div>
      ));
    }
    return (<></>);
  }
  

  removePicture = image => {
    this.setState({ photo: null });
    this.setState({ photoUrl: null });
    api
        .post("imagens/deletar", {exclude: image, locale: "poster"})
        .then(response => {
        })
        .catch(error => {
        });
  };

    removeGallery(image){
    const links = this.state.galleryUrl

    function check(link) {
      return link === image;
    }

    let index = links.findIndex(check);
    links.splice(index, 1);
    this.setState({ galleryUrl: links});
    api
        .post("imagens/deletar", {exclude: image, locale: "gallery"})
        .then(response => {
        })
        .catch(error => {
        });
  };

  render() {
    const {
      loading,
      name,
      biograpy,
      date,
      dead_date,
      birthplace,
      deadplace
    } = this.state;

    return (
      <>
        <React.Fragment>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Elenco</h3>
                  </CardHeader>
                  <form
                    className="modal-body"
                    onSubmit={this.submitForm}
                    method="post"
                  >
                    <Label>Nome</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Nome"
                      value={name}
                      name="name"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Biografia</Label>
                    <Editor
                      editorState={biograpy}
                      placeholder="Biografia"
                      className="addInput_field_textArea"
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    <Label>Data de nascimento</Label>
                    <Input
                      className="addInput_field"
                      value={date}
                      name="date"
                      type="date"
                      onChange={this.handleInputChange}
                    />
                    <Label>Data de morte</Label>
                    <Input
                      className="addInput_field"
                      value={dead_date}
                      name="dead_date"
                      type="date"
                      onChange={this.handleInputChange}
                    />
                    <Label>País de nascimento</Label>
                    <Input
                      className="addInput_field"
                      placeholder="País de nascimento"
                      value={birthplace}
                      name="birthplace"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>País de morte</Label>
                    <Input
                      className="addInput_field"
                      placeholder="País de morte"
                      value={deadplace}
                      name="deadplace"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Foto de perfil</Label>
                    <br />
                    <Label
                      for="photo"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar Foto de perfil
                    </Label>
                    <br />
                    <Input
                      style={{ display: "none" }}
                      className="addInput_field"
                      name="photo"
                      type="file"
                      id="photo"
                      onChange={this.handleInputChange}
                    />
                    <br />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadPoster}
                    >
                      {!loading ? (
                        "Upload"
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                    {this.state.photoUrl === "" ||
                    this.state.photoUrl === null ? (
                      <div>
                        <img
                          style={{ display: "none" }}
                          alt=""
                          className="img-thumbnail"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={this.state.photoUrl}
                          alt=""
                          className="img-thumbnail"
                        />
                        <Button
                          color="primary"
                          className="py-2 px-3 btn btn-primary btn-sm"
                          size="sm"
                          onClick={() => this.removePicture(this.state.photoUrl)}
                        >
                          Remover
                        </Button>
                      </div>
                    )}
                    <Label>Galeria de imagens</Label>
                    <br />
                    <Label
                      for="gallery"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar galeria de imagens
                    </Label>
                    <Input
                      style={{ display: "none" }}
                      className="addInput_field"
                      name="gallery[]"
                      type="file"
                      multiple
                      id="gallery"
                      onChange={this.handleGalleryChange}
                    />
                    <br />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadGallery}
                    >
                      {!loading ? (
                        "Upload"
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>               
                    <div
                      className="div"
                      style={{ display: !this.state.galleryUrl && "none", paddingBottom: 50 }}
                    >
                      {this.getGalleryUrl(this.state.galleryUrl)}
                    </div>
                    <br />
                    <br />
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className="notification-modal__button"
                    >
                      {!loading ? (
                        "Salvar"
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </React.Fragment>
      </>
    );
  }
}
