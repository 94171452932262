import React from 'react'
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { Modal } from "@material-ui/core";
import Header from '../../../components/Headers/Header.jsx'
import SuccessModal from '../../../components/SuccessModal'
import AlertModal from '../../../components/AlertModal'
import { Link } from 'react-router-dom'
import api from '../../../Services/api'
import EmailModal from '../../../components/EmailModal';
import SnackBar from '../../../components/Snackbar';

class Users extends React.Component {
  state = {
    successModal: false,
    alertModal: false,
    removeModal: false,
    loading: false,
    modal: false,
    redirect: false,
    search: null,
    reqSuccess: false,
    result: [],
    id: null,
    active: null,
    data: [],
    emailModal: false,
    clientModal: false,
  }

  componentDidMount() {
    this.setState({ loading: true })
    const { search } = this.state
    api
      .post('/usuarios', {
        filter: search
      })
      .then(resp => {
        if (resp.data.status) {
          this.setState({ loading: false })
          this.setState({ reqSuccess: true })
          this.setState({ result: resp.data.response.message })
        } else {
          this.setState({ reqSuccess: false })
          this.setState({ loading: false })
          this.setState({ result: this.state.result })
        }
      })
      .catch(error => {
        this.setState({ loading: true })
        console.log(error)
      })
  }

  confirmExclude = e => {
    e.preventDefault();
    api
      .post("/filmes/deletar", {
        id_mv: this.state.id
      })
      .then(response => {
        setTimeout(window.location.reload(), 1000);
      })
      .catch(error => {
        console.log(error);
      });
  };

  buscarId(id) {
    this.setState({
      id: id
    });
    return id;
  }

  setActive(active) {
    if (active === 1) {
      this.setState({ active: 0 })
    } else {
      this.setState({ active: 1 })
    }
  }

  toggleModal = modal => {
    this.setState(state => ({
      [modal]: !state[modal]
    }))
  }

  handleInputChange = e => {
    const { name, value } = e.target
    this.setState(() => ({ [name]: value }))
  }

  search = async e => {
    e.preventDefault()
    this.setState({
      search: e.target.search.value,
      reqSuccess: null,
      loading: true,
    });
    const search = e.target.search.value;
    await api
      .post('/usuarios', {
        filter: search
      })
      .then(resp => {
        if (resp.data.status) {
          this.setState({ loading: false })
          this.setState({ reqSuccess: true })
          this.setState({ result: resp.data.response.message })
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Usuário não encontrado!',
            snackVariant: 'error',
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de comunicacão com servidor : ${error}`,
          snackVariant: 'error',
        });
        console.log(error)
      })
  }

  confirmExclude = e => {
    e.preventDefault();
    api
      .post("/usuarios/moderar", {
        id: this.state.id,
        active: this.state.active
      })
      .then(response => {
        setTimeout(window.location.reload(), 1000);
      })
      .catch(error => {
      });
  };

  render() {
    const { successModal, alertModal, removeModal } = this.state
    return (
      <React.Fragment>
        <Header />
        <Container className='mt--7' fluid>
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>Usuários</h3>
                </CardHeader>
                <Form
                  className='navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex'
                  onSubmit={this.search}
                >
                  <FormGroup className='mb-0'>
                    <InputGroup className='input-group-alternative' style={{backgroundColor:"white", marginLeft:"20px", marginBottom:"20px"}}>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='fas fa-search' style={{color:"black"}}/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Pesquisar"
                        name="search"
                        type="text"
                        style={{ color: 'black' }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">CPF</th>
                      <th scope="col">Tipo</th>
                      <th scope="col">Ativo</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading === true && (<i className="fa fa-spinner fa-pulse" />)}
                    {this.state.reqSuccess ? (
                      this.state.result.map((result, key) => {
                        return (
                          <>
                            <tr key={key}>
                              <th scope="row">
                                <span className="mb-0 text-sm">{result.fullname}</span>
                              </th>
                              <td>{`${result.cpf.slice(0, 3)}.${result.cpf.slice(3, 6)}.${result.cpf.slice(6, 9)}-${result.cpf.slice(9, 11)}`}</td>
                              <td>{result.type_account === 'Free' ? "Gratuito" : "Membro"}</td>
                              <td>{result.active === '1' ? "Sim" : "Não"}</td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    style={{ color: '#fa6545' }}
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <Link to={`/admin/usuarios/editar/${result.id}`}>
                                      <DropdownItem>Editar</DropdownItem>
                                    </Link>
                                    <DropdownItem
                                      onClick={() => {
                                        this.setState({ data: { fullname: result.fullname, email: result.email }, emailModal: true });
                                      }}
                                    >
                                      Enviar email
                                    </DropdownItem>
                                    <Link to={`/admin/usuarios/${result.id}`}>
                                      <DropdownItem>Visualizar</DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                        <h1 style={{ margin: "20px" }}>
                          {this.state.loading === true
                            ? ""
                            : "Nenhum usuário encontrado!"}
                        </h1>
                      )}
                  </tbody>
                  {removeModal && (
                    <Modal
                      open
                      onClose={() => this.toggleModal("removeModal")}
                      onSuccess={() => {
                        this.toggleModal("removeModal");
                        this.toggleModal("successModal");
                      }}
                      onError={() => this.toggleModal("alertModal")}
                    >
                      <div className="alert__content">
                              <h1 className="alert__title">Aviso</h1>
                              <h2 className="alert__subheader">Deseja realmente alterar?</h2>
                              <button className="alert__button" onClick={this.confirmExclude}>
                                Confirmar
                              </button>
                      </div>
                    </Modal>
                  )}
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...' />
                </CardFooter>
              </Card>
            </div>
          </Row>
          <EmailModal
            data={this.state.data}
            open={this.state.emailModal}
            onClose={() => this.setState({ emailModal: false })}
          />
          <SuccessModal
            show={successModal}
            onClick={() => this.toggleModal('successModal')}
          />

          <AlertModal
            show={alertModal}
            onClick={() => this.toggleModal('alertModal')}
            title='Preencha todos os campos'
            subheader='É necessário informar todos os dados para cadastrar um novo filme!'
          />
        </Container>
        <SnackBar
          isOpen={this.state.snack}
          isVariant={this.state.snackVariant}
          isMessage={this.state.snackMessage}
          isClose={() => this.setState({ snack: false })}
        />
      </React.Fragment>
    )
  }
}

export default Users
