import React from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';
import './css/nucleo.css';
import './css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Redirect from="/" to="auth/login" />
      <Route path="*" component={() => <h1>Página não Encontrada!</h1>} />
    </Switch>
  </HashRouter>,
  document.getElementById('root')
);
