import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input,
} from 'reactstrap';
import firebase from 'firebase';
import {
  EditorState, ContentState, convertFromHTML, convertToRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import draftToHtml from 'draftjs-to-html';
import { CircularProgress } from '@material-ui/core';
import Header from '../../../components/Headers/Header.jsx';
import api from '../../../Services/api';

const options = [{ value: 1, label: 'Sim' }, { value: 0, label: 'Não' }];

export default class EditCast extends Component {
  constructor({ match }) {
    super();
    this.state = {
      slug: match.params.slug,
      loading: false,
      id_ct: '',
      id_dr: '',
      fullname: '',
      biography: '',
      birth_cidade: null,
      date_birth: null,
      date_death: null,
      death_cidade: null,
      profile_image: '',
      gallery: '',
      profile_imageUrl: '',
      galleryUrl: '',
      editorState: null,
      newPicture: '',
      newGallery: '',
    };
  }

  componentDidMount() {
    this.getCastData();
  }

  getCastData = () => {
    api
      .get(`https://www.vamuvetv.com/vamuvetv-site-api/elenco/${this.state.slug}`)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          const result = data.response.message;
          this.setState({
            actor: data.response.message,
            id_ct: result.id_ct,
            id_dr: result.id_dr,
            fullname: result.fullname,
            biography: result.biography,
            birth_cidade: result.birth_cidade,
            date_birth: result.date_birth,
            date_death: result.date_death,
            death_cidade: result.death_cidade,
            profile_image: result.profile_image,
            profile_imageUrl: result.profile_image,
            galleryUrl: result.gallery === null || result.gallery === '' ? [] : result.gallery.split(', '),

            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(`${result.biography}`)
              )
            ),
          });
          this.getGalleryUrl(this.state.gallery);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    if (e.target.files) {
      const picture = e.target.files[0];
      this.setState({ newPicture: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  handleGaleryChange = (e) => {
    const { name, value } = e.target;

    if (e.target.files) {
      const picture = e.target.files;
      this.setState({ newGallery: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  uploadPicture = () => {
    const image = this.state.newPicture;
    const data = new FormData();
    data.append('image', image);
    data.append('locale', 'poster');
    api
      .post('imagens/inserir', data)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({ profile_imageUrl: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Erro de uploading',
            snackVariant: 'error',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error',
        });
      });
  };

  uploadGallery = () => {
    const urls = this.state.galleryUrl;
    const image = this.state.newGallery;
    for (let i = 0; i < image.length; i++) {
      const data = new FormData();
      data.append('image', image[i]);
      data.append('locale', 'gallery');
      api
        .post('imagens/inserir', data)
        .then((resp) => {
          const { data } = resp;
          if (data.status) {
            urls.push(data.response.message);
            this.setState({ galleryUrl: urls });
          } else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Houve algum erro desconhecido no upload',
              snackVariant: 'error',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: 'error',
          });
        });
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    const actor = { ...this.state };
    let decoded = [];

    if (typeof actor.biography === 'object') {
      decoded = draftToHtml(convertToRaw(actor.biography.getCurrentContent()));
    } else {
      decoded = actor.biography;
    }
    this.setState({ loading: true });
    api
      .post('/elenco/editar', {
        id_ct: actor.id_ct,
        id_dr: actor.id_dr,
        fullname: actor.fullname,
        biography: decoded,
        date_birth: actor.date_birth,
        date_death: actor.date_death,
        birth_cidade: actor.birth_cidade,
        death_cidade: actor.death_cidade,
        picture: actor.profile_imageUrl,
        gallery: actor.galleryUrl !== null ? actor.galleryUrl.join(', ') : actor.galleryUrl,
      })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Integrante editado com sucesso!',
            snackVariant: 'success',
          });
          setTimeout(() => { window.location.href = '/#/admin/elenco'; }, 2000);
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Confira os dados!',
            snackVariant: 'warning',
          });
        }
      })
      .catch(function (error) {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error',
        });
      });
  };

  onEditorStateChange = (biography) => {
    this.setState({
      biography,
    });
  };

  removePicture = (image) => {
    this.setState({ profile_image: null });
    this.setState({ profile_imageUrl: null });
    api
      .post('imagens/deletar', { exclude: image, locale: 'poster' })
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  removeGallery(image) {
    const links = this.state.galleryUrl;

    function check(link) {
      return link === image;
    }

    const index = links.findIndex(check);
    links.splice(index, 1);
    this.setState({ galleryUrl: links });
    api
      .post('imagens/deletar', { exclude: image, locale: 'gallery' })
      .then((response) => {
      })
      .catch((error) => {
      });
  }

  getGalleryUrl(string) {
    if (string) {
      return string.map(image => (
        <div className="block__images">
          <img src={image} alt="" className="img__modal" />
          <Button
            color="primary"
            className="py-2 px-3 small button__img"
            size="medium"
            onClick={() => this.removeGallery(string)}
          >
            Remover
          </Button>
        </div>
      ));
    }
    return (<></>);
  }

  render() {
    const { loading } = this.state.loading;
    return (
      <>
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Editar Elenco</h3>
                  </CardHeader>
                  <form
                    className="modal-body"
                    onSubmit={this.submitForm}
                    method="post"
                  >
                    <Label>Nome do Ator/Diretor</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Nome do Ator/Diretor"
                      name="fullname"
                      value={this.state.fullname}
                      type="text"
                      onChange={this.handleInputChange}
                    />
                    <Label>Biografia</Label>
                    {this.state.editorState && (
                    <Editor
                      defaultEditorState={this.state.editorState}
                      placeholder="Biografia"
                      className="addInput_field_textArea"
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    )}
                    <Label>Data de nascimento</Label>
                    <Input
                      className="addInput_field"
                      value={this.state.date_birth}
                      name="date_birth"
                      type="date"
                      onChange={this.handleInputChange}
                    />
                    <Label>Local de nascimento</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Local de nascimento"
                      value={this.state.birth_cidade}
                      name="birth_cidade"
                      type="text"
                      onChange={this.handleInputChange}
                    />
                    <Label>Data de morte</Label>
                    <Input
                      className="addInput_field"
                      value={this.state.date_death}
                      name="date_death"
                      type="date"
                      onChange={this.handleInputChange}
                    />
                    <Label>Local de morte</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Local de morte"
                      value={this.state.death_cidade}
                      name="death_cidade"
                      type="text"
                      onChange={this.handleInputChange}
                    />
                    <Label>Foto</Label>
                    <br />
                    {this.state.profile_imageUrl === ''
                      || this.state.profile_imageUrl === null ? (
                        <>
                          <img
                            src={this.state.profile_imageUrl}
                            style={{ display: 'none' }}
                            alt=""
                            className="img-thumbnail"
                          />
                          <Button
                            color="primary"
                            style={{ display: 'none' }}
                            className="py-2 px-3 small"
                            size="sm"
                            onClick={() => this.removePicture(this.state.profile_imageUrl)}
                          >
                            Remover
                          </Button>
                          <br />
                        </>
                      ) : (
                        <>
                          <img
                            src={this.state.profile_imageUrl}
                            alt=""
                            className="img-thumbnail"
                          />
                          <Button
                            color="primary"
                            className="py-2 px-3 small"
                            size="sm"
                            onClick={() => this.removePicture(this.state.profile_imageUrl)}
                          >
                            Remover
                          </Button>
                          <br />
                        </>
                      )}
                    <Label
                      for="newPicture"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar foto
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className="addInput_field"
                      name="newPicture"
                      type="file"
                      id="newPicture"
                      onChange={this.handleInputChange}
                    />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadPicture}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                    <br />
                    <Label>Galeria</Label>
                    <br />
                    <div
                      className="div"
                      style={{ display: (!this.state.galleryUrl || this.state.galleryUrl.length === 0) && 'none', paddingBottom: 50 }}
                    >
                      {this.getGalleryUrl(this.state.galleryUrl)}
                    </div>
                    <br />
                    <Label
                      for="newGallery"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar galeria de imagens
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className="addInput_field"
                      name="newGallery[]"
                      type="file"
                      multiple
                      id="newGallery"
                      onChange={this.handleGaleryChange}
                    />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadGallery}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                    <br />
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className="notification-modal__button"
                    >
                      Salvar
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      </>
    );
  }
}
