import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import api from '../../../Services/api';
import Header from '../../../components/Headers/Header';
import SnackBar from '../../../components/Snackbar';

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      subscriptionPrice: null,
      snack: false,
      snackMessage: null,
      snackVariant: null,
    };
  }

  getMovies = (search, { field, dir }) => {
    this.setState({ loadingSearch: true });
    api
      .post(`filmes/pesquisar?order_by=${field}&dir=${dir}`, {
        filter: search,
      })
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({
            reqSuccess: data.response.message,
            loading: false,
            loadingSearch: false,
          });
        } else {
          this.setState({
            loading: false,
            loadingSearch: false,
            snack: true,
            snackMessage: 'Filme não encontrado!',
            snackVariant: 'error',
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingSearch: false,
          snack: true,
          snackMessage: `Erro de comunicacão com servidor : ${err}`,
          snackVariant: 'error',
        });
      });
  }

  submitForm = (e) => {
    e.preventDefault();
    const {
      subscriptionPrice,
    } = this.state;

    if (!subscriptionPrice) {
      this.setState({
        snack: true,
        snackMessage: 'Preencha todos os dados necessários.',
        snackVariant: 'error',
      });
    } else {
      this.setState({ loading: true });
      api
        .post('/settings', { subscriptionPrice })
        .then((response) => {
          if (response.data.status) {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Configurações salvas!',
              snackVariant: 'success',
            });
            setTimeout(() => { window.location.href = '/#/configuracao'; }, 2000);
          } else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Algo deu errado, confira os dados',
              snackVariant: 'warning',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: 'error',
          });
        });
    }
  };

  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Configurações</h3>
                </CardHeader>
                <form
                  className="settings-form"
                  onSubmit={this.submitForm}
                  method="post"
                >
                  <Label>Preço da Assinatura</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
                    <Input
                      placeholder="Preço da Assinatura"
                      name="subscription-price"
                      type="number"
                      value={this.state.subscriptionPrice}
                      style={{ color: 'black', maxWidth: '200px' }}
                    />
                  </InputGroup>

                  <CardFooter className="py-4">
                    <Button type="submit" color="primary" className="py-2 px-3" size="sm" disabled={this.state.loading}>
                      Salvar
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </div>
          </Row>
        </Container>
        <SnackBar
          isOpen={this.state.snack}
          isVariant={this.state.snackVariant}
          isMessage={this.state.snackMessage}
          isClose={() => this.setState({ snack: false })}
        />
      </>
    );
  }
}

export default Settings;
