import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input
} from "reactstrap";
import { CircularProgress } from "@material-ui/core";
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import Header from "../../../components/Headers/Header.jsx";
import api from "../../../Services/api";
import SnackBar from "../../../components/Snackbar/index.js";

export default class AddProgram extends React.Component {
  state = {
    title: "",
    content: EditorState.createEmpty(),
    cover: "",
    coverUrl: null,
    loading: false,
    snack: false,
    snackVariant: null,
    snackMessage: null
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  handleCoverChange = e => {
    const { name, value } = e.target;

    if (e.target.files) {
      const picture = e.target.files[0];
      this.setState({ cover: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  uploadCover = () => {
    const image = this.state.cover;
    const data = new FormData();
    data.append("image", image);
    data.append("locale", "poster");
    api
      .post("imagens/inserir", data)
      .then(resp => {
        const data = resp.data;
        if (data.status) {
          this.setState({ coverUrl: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Erro de uploading",
            snackVariant: "error"
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: "error"
        });
      });
  };

  submitForm = e => {
    e.preventDefault();
    const { title, content, coverUrl } = this.state;

    if (!title || !content || !coverUrl) {
      this.setState({
        snack: true,
        snackMessage: "Prrencha todos os dados necessários.",
        snackVariant: "error"
      });
    }
    else {
      this.setState({ loading: true });
      const decoded = draftToHtml(convertToRaw(content.getCurrentContent()));

      api
        .post("/noticias/inserir", {
          title: title,
          text: decoded,
          image: coverUrl
        })
        .then(response => {
          if (response.data.status) {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: "Noticia adicionada com sucesso!",
              snackVariant: "success"
            });
            setTimeout(window.location.reload(), 4000);
          }
          else{
            this.setState({
              loading: false,
              snack: true,
              snackMessage: "Confira os dados!",
              snackVariant: "warning"
            });
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: "error"
          });
        });
    }
  };

  removeCover = image => {
    this.setState({ cover: null });
    this.setState({ coverUrl: null });
    api
        .post("imagens/deletar", {exclude: image, locale: "poster"})
        .then(response => {
        })
        .catch(error => {
        });
  };

  onEditorStateChange = content => {
    this.setState({
      content
    });
  };

  render() {
    const { loading, title, content } = this.state

    return (
      <>
        <React.Fragment>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Notícias</h3>
                  </CardHeader>
                  <form
                    className="modal-body"
                    onSubmit={this.submitForm}
                    method="post"
                  >
                    <Label>Título</Label>
                    <Input
                      className="addInput_field"
                      value={title}
                      name="title"
                      type="name"
                      onChange={this.handleInputChange}
                    />
                    <Label>Conteúdo</Label>
                    <Editor
                      editorState={content}
                      placeholder="Conteúdo"
                      className="addInput_field_textArea"
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    <Label>Capa</Label>
                    <br />
                    <Label
                      for="capa"
                      className="py-2 px-3 btn btn-primary btn-sm"
                      size="sm"
                    >
                      Adicionar capa
                    </Label>
                    <Input
                      style={{ display: "none" }}
                      className="addInput_field"
                      name="capa"
                      type="file"
                      id="capa"
                      onChange={this.handleCoverChange}
                    />
                    <br />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={this.uploadCover}
                    >
                      {!loading ? (
                        "Upload"
                      ) : (
                          <CircularProgress color="inherit" size={20} />
                        )}
                    </Button>
                    {this.state.coverUrl === "" ||
                      this.state.coverUrl === null ? (
                        <div>
                          <img
                            src={this.state.coverUrl}
                            style={{ display: "none" }}
                            alt=""
                            className="img-thumbnail"
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={this.state.coverUrl}
                            alt=""
                            className="img-thumbnail"
                          />
                          <Button
                            color="primary"
                            className="py-2 px-3 btn btn-primary btn-sm"
                            size="sm"
                            onClick={() => this.removeCover(this.state.coverUrl)}
                          >
                            Remover
                          </Button>
                        </div>
                      )}
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className="notification-modal__button"
                    >
                      {!loading ? (
                        "Salvar"
                      ) : (
                          <CircularProgress color="inherit" size={20} />
                        )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </React.Fragment>
      </>
    );
  }
}
