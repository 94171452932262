import React from "react";
import { Modal } from "@material-ui/core";
import successImage from "../../assets/success.png";
import "../../css/styles.css";

export default function SuccessModal({ show, onClick }) {
  return (
    <Modal open={show}>
      <div className="success">
        <div className="success__image__container">
          <img
            src={successImage}
            alt="Success icon"
            className="success__image"
          />
        </div>
        <div className="success__content">
          <h1 className="success__title">Operação realizada com sucesso!</h1>
          <h2 className="success__subheader">
            Clique no botão abaixo para continuar a navegação
          </h2>
          <button className="success__button" onClick={onClick}>
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  );
}
