import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input
} from "reactstrap";
import api from "./../../../Services/api";
import Header from "../../../components/Headers/Header.jsx";
import { CircularProgress } from "@material-ui/core";
import Select from 'react-select'
import SnackBar from "../../../components/Snackbar/index.js";

export default class EditCast extends Component {
  constructor({ match }) {
    super();
    this.state = {
      pg_id: match.params.id,
      loading: false,
      id: "",
      id_movie: "",
      mv_title: "",
      start: "",
      hour_start: "",
      newMovie: "",
      title: "",
      snack: false,
      snackVariant: null,
      snackMessage: null
    };
  }

  componentDidMount() {
    this.getCastData();
    this.getMovie();
  }

  getCastData = async () => {
    await api
      .get(`/programacoes/${this.state.pg_id}/`)
      .then(resp => {
        const data = resp.data;
        if (data.status) {
          const result = data.response.message;
          this.setState({ id: result.id });
          this.setState({ id_movie: result.id_movie });
          this.setState({ mv_title: result.mv_title });
          this.setState({ start: result.start });
          this.setState({ hour_start: result.hour_start });
          this.setState({ title: result.mv_title });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  getMovie = () => {
    const { mv_title } = this.state;
    let newArray = [];
    newArray.push({ label: mv_title, value: mv_title });
    this.setState({ newMovie: mv_title });
    this.setState({ title: newArray });
  };

  handleInputChange = e => {
    const { name, value } = e.target;

    this.setState(() => ({ [name]: value }));
  };

  submitForm = e => {
    e.preventDefault();
    const programming = { ...this.state };
    this.setState({ loading: true });
    api
      .post("/programacoes/editar", {
        id_pg: programming.id,
        start: programming.start,
        hour_start: programming.hour_start
      })
      .then(response => {
        if (response.data.status) {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Programação editada com sucesso!",
            snackVariant: "success"
          });
          setTimeout(() => {window.location.href = '/#/admin/programacao'}, 2000)
        }
        else if (response.data.response.message === 'Already have a movie on this hour') {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Já existe um filme nesse horário!",
            snackVariant: "warning"
          });
        }
        else{
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Confira os dados!",
            snackVariant: "warning"
          });
        }
      })
      .catch(function(error) {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: "error"
        });
      });
  };

  render() {
    const { loading } = this.state.loading;
    return (
      <>
        <React.Fragment>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Editar Programação</h3>
                  </CardHeader>
                  <form
                    className="modal-body"
                    onSubmit={this.submitForm}
                    method="post"
                  >
                    <Label>Nome do Filme</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Filme"
                      value={`${this.state.id_movie} - ${this.state.mv_title}`}
                      disabled
                    />
                    <Label>Data de Início</Label>
                    <Input
                      className="addInput_field"
                      value={this.state.start}
                      name="start"
                      type="date"
                      onChange={this.handleInputChange}
                    />
                    <Label>Hora de Início</Label>
                    <Input
                      className="addInput_field"
                      value={this.state.hour_start}
                      name="hour_start"
                      type="time"
                      onChange={this.handleInputChange}
                    />
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className="notification-modal__button"
                    >
                      {!loading ? (
                        "Salvar"
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </React.Fragment>
      </>
    );
  }
}
