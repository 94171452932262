import React, { Component } from 'react';
import {
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { Modal } from '@material-ui/core';
import { Link } from 'react-router-dom';
import api from '../../Services/api';

let prev = 0;
let last = 0;

class FilmList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      currentFilm: null,
      currentPage: 1,
      todosPerPage: 20,
      removeModal: false,
      successModal: false,
      alertModal: false,
      commingsoon: false,
      reqSuccess: false,
      loading: false,
      orderBy: {
        field: 'title',
        dir: 'asc',
      },
    };
  }

  componentDidMount() {
    const { orderBy } = this.state;
    this.getMovies(orderBy);
  }

  getMovies = async ({ field, dir }) => {
    this.setState({ loading: true });
    await api
      .get(`/filmes?order_by=${field}&dir=${dir}`)
      .then((res) => {
        this.setState({
          currentPage: 1,
          reqSuccess: res.data.status,
          requests: res.data.response.message,
        });
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error',
        });
      });

    this.setState({ loading: false });
    this.setState({
      requests: [...this.state.requests],
    });
    this.setState({ loading: false });
  }

  buscarId = (id) => {
    this.setState({
      id,
    });
    return id;
  }

  confirmExclude = (e) => {
    e.preventDefault();
    api
      .post('/filmes/deletar', {
        id_mv: this.state.id,
      })
      .then(() => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Filme Removido com sucesso,
           aguarde o painel atualizar...`,
          snackVariant: 'success',
        });
        setTimeout(window.location.reload(), 2000);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error',
        });
      });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      currentPage: Number(e.target.id),
    });
  }

  handleLastClick = (e) => {
    e.preventDefault();
    this.setState({
      currentPage: last,
    });
  }

  handleFirstClick = (e) => {
    e.preventDefault();
    this.setState({
      currentPage: 1,
    });
  }

  toggleModal = (modal) => {
    this.setState(state => ({
      [modal]: !state[modal],
    }));
  }

  getUrl = (string) => {
    if (string) {
      const object = JSON.parse(string);
      return <img className="img-thumbnail" alt="..." src={object.url} />;
    }
    return [];
  }

  getArrowActive = (field) => {
    const { orderBy } = this.state;
    let classArrow = 'fa-sort';
    if (orderBy.field === field) {
      classArrow = `fa-sort-${orderBy.dir === 'asc' ? 'up' : 'down'}`;
    }
    return classArrow;
  };

  getNextOrder = (field) => {
    const { orderBy } = this.state;
    let dir = 'asc';
    if (orderBy.field === field) {
      dir = orderBy.dir === 'asc' ? 'desc' : 'asc';
    }
    this.setState({
      orderBy: { field, dir },
    });
    this.getMovies({ field, dir });
  }

  render() {
    const {
      requests, currentPage, todosPerPage, removeModal,
    } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = requests.slice(indexOfFirstTodo, indexOfLastTodo);
    prev = currentPage > 0 ? currentPage - 1 : 0;
    last = Math.ceil(requests.length / todosPerPage);

    const pageNumbers = [];
    for (let i = 1; i <= last; i++) {
      pageNumbers.push(i);
    }

    return (
      <>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">
                Título
                <i
                  className={`icon-table fa ${this.getArrowActive('title')}`}
                  onClick={() => this.getNextOrder('title')}
                  aria-hidden="true"
                />
              </th>
              <th scope="col">
                Duração(min)
                <i
                  className={`icon-table fa ${this.getArrowActive('duration')}`}
                  onClick={() => this.getNextOrder('duration')}
                  aria-hidden="true"
                />
              </th>
              <th scope="col">
                Diretor
                <i
                  className={`icon-table fa ${this.getArrowActive('director')}`}
                  onClick={() => this.getNextOrder('director')}
                  aria-hidden="true"
                />
              </th>
              <th scope="col">
                Data de Lançamento
                <i
                  className={`icon-table fa ${this.getArrowActive('release_date')}`}
                  onClick={() => this.getNextOrder('release_date')}
                  aria-hidden="true"
                />
              </th>
              <th scope="col">Em Destaque</th>
              <th scope="col">Em Breve</th>
              <th scope="col">Poster</th>
              <th scope="col">Banner</th>
              <th scope="col" />
            </tr>
          </thead>
          {this.state.loading ? (
            <tbody>
              <tr>
                <td colSpan={9} align="center">
                  <i className="fa fa-spinner fa-pulse" />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {this.state.reqSuccess ? (
                currentTodos.map((result, key) => (
                  <tr key={key}>
                    <td style={{ whiteSpace: 'normal' }}>
                      <span className="mb-0 text-sm">{result.mv_title}</span>
                    </td>
                    <td>{result.mv_duration}</td>
                    <td className="serie-title">
                      <p className="mb-0 text-sm">{result.mv_director}</p>
                    </td>
                    <td>
                      {result.release_date !== '' && result.release_date !== null
                        ? result.release_date
                          .substr(0, 10)
                          .split('-')
                          .reverse()
                          .join('/')
                        : ''}
                    </td>
                    <td>{result.highlighted === '1' ? 'Sim' : 'Não'}</td>
                    <td>{result.commingsoon === '1' ? 'Sim' : 'Não'}</td>
                    <td>
                      {!result.mv_poster ? (
                        <img
                          className="img-thumbnail"
                          alt="..."
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <img
                          className="img-thumbnail"
                          alt="..."
                          src={result.mv_poster}
                        />
                      )}
                    </td>
                    <td>
                      {!result.mv_banner ? (
                        <img
                          className="img-thumbnail"
                          alt="..."
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <img
                          className="img-thumbnail"
                          alt="..."
                          src={result.mv_banner}
                        />
                      )}
                    </td>
                    <td className="text-right">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only"
                          href="#"
                          role="button"
                          size="sm"
                          color=""
                          style={{ color: '#fa6545' }}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <Link to={`/admin/filmes/editar/${result.slug}`}>
                            <DropdownItem>Editar</DropdownItem>
                          </Link>
                          <DropdownItem
                            onClick={() => {
                              this.toggleModal('removeModal');
                              this.buscarId(result.id_mv);
                            }}
                          >
                            Excluir
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <h1 style={{ margin: '20px' }}>
                  {!this.state.loading
                    && 'Ainda não existe filmes cadastrados!'}
                </h1>
              )}
            </tbody>
          )}
          {removeModal && (
          <Modal
            open
            onClose={() => this.toggleModal('removeModal')}
            onSuccess={() => {
              this.toggleModal('removeModal');
              this.toggleModal('successModal');
            }}
            onError={() => this.toggleModal('alertModal')}
          >
            <div className="alert__content">
              <h1 className="alert__title">Alerta</h1>
              <h2 className="alert__subheader">
                Deseja realmente excluir?
              </h2>
              <button
                className="alert__button"
                onClick={this.confirmExclude}
              >
                Sim, desejo.
              </button>
            </div>
          </Modal>
          )}
        </Table>
        <ul id="page-numbers">
          <nav>
            <Pagination size="sm" style={{ display: 'flex', justifyContent: 'center' }}>
              <PaginationItem>
                {prev === 0 ? (
                  <PaginationLink disabled>{'<<'}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleFirstClick}
                    id={prev}
                    href={prev}
                  >
                    {'<<'}
                  </PaginationLink>
                )}
              </PaginationItem>
              <PaginationItem>
                {prev === 0 ? (
                  <PaginationLink disabled>{'<'}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleClick}
                    id={prev}
                    href={prev}
                  >
                    {'<'}
                  </PaginationLink>
                )}
              </PaginationItem>
              {pageNumbers.map((number, i) => (
                number >= currentPage - 4
                && number <= currentPage + 4 ? (
                  <Pagination key={i}>
                    <PaginationItem
                      active={pageNumbers[currentPage - 1] === number}
                    >
                      <PaginationLink
                        onClick={this.handleClick}
                        href={number}
                        key={number}
                        id={number}
                      >
                        {number}
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                  ) : null
              ))}
              <PaginationItem>
                {currentPage === last ? (
                  <PaginationLink disabled>{'>'}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleClick}
                    id={pageNumbers[currentPage]}
                    href={pageNumbers[currentPage]}
                  >
                    {'>'}
                  </PaginationLink>
                )}
              </PaginationItem>
              <PaginationItem>
                {currentPage === last ? (
                  <PaginationLink disabled>{'>>'}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleLastClick}
                    id={pageNumbers[currentPage]}
                    href={pageNumbers[currentPage]}
                  >
                    {'>>'}
                  </PaginationLink>
                )}
              </PaginationItem>
            </Pagination>
          </nav>
        </ul>
      </>
    );
  }
}

export default FilmList;
