import React from 'react'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input
} from 'reactstrap'
import { CircularProgress } from '@material-ui/core'
import Select from 'react-select'
import Header from '../../../components/Headers/Header.jsx'
import api from '../../../Services/api'
import SnackBar from '../../../components/Snackbar/index.js'

export default class AddProgram extends React.Component {
  state = {
    start: '',
    films: '',
    film: '',
    hour: '',
    filmReq: [],
    loading: false,
    newFilm: ''
  }

  componentDidMount () {
    this.filmRequest()
  }

  filmRequest = () => {
    api
      .post('/filmes')
      .then(res => {
        this.setState({ filmReq: res.data.response.message })
      })
      .catch(error => console.log(error))
    this.setState({
      filmReq: [...this.state.filmReq]
    })
  }

  filmSelect = () => {
    const resultado = this.state.filmReq.map(
      result =>
        (this.state.film = {
          value: result.id_mv,
          label: result.mv_title
        })
    )
    return resultado
  }

  handleChange = films => {
    let newdata = []
    newdata.push({ film: films.value })
    this.setState({ newFilm: films.value })
    this.setState({ films })
  }

  handleInputChange = e => {
    const { name, value } = e.target
    this.setState(() => ({ [name]: value }))
  }

  submitForm = e => {
    e.preventDefault()
    const { start, films, hour, newFilm } = this.state

    if (!start || !films || !hour) {
      this.setState({
        snack: true,
        snackMessage: 'Prrencha todos os dados necessários.',
        snackVariant: 'error'
      })
    }
    this.setState({ loading: true })
    api
      .post('/programacoes/inserir', {
        id_movie: newFilm,
        start: start,
        hour_start: hour
      })
      .then(response => {
        if (response.data.status) {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Programação adicionada com sucesso!",
            snackVariant: "success"
          });
          setTimeout(() => {window.location.href = '/#/admin/programacao'}, 2000)
        }
        else if (response.data.response.message === 'Already have a movie on this hour') {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Já existe um filme nesse horário!",
            snackVariant: "warning"
          });
        }
        else{
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Confira os dados!",
            snackVariant: "warning"
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error'
        })
      })
  }

  render () {
    const { loading, films, start, hour } = this.state

    return (
      <>
        <React.Fragment>
          <Header />
          <Container className='mt--7' fluid>
            <Row>
              <div className='col'>
                <Card className='shadow'>
                  <CardHeader className='border-0'>
                    <h3 className='mb-0'>Programação</h3>
                  </CardHeader>
                  <form
                    className='modal-body'
                    onSubmit={this.submitForm}
                    method='post'
                  >
                    <Label>Nome do Filme</Label>
                    <Select
                      className='addInput_field'
                      placeholder='Filme'
                      value={films}
                      onChange={this.handleChange}
                      options={this.filmSelect()}
                    />
                    <Label>Data de Início</Label>
                    <Input
                      className='addInput_field'
                      value={start}
                      name='start'
                      type='date'
                      onChange={this.handleInputChange}
                    />
                    <Label>Hora de Início</Label>
                    <Input
                      className='addInput_field'
                      value={hour}
                      name='hour'
                      type='time'
                      onChange={this.handleInputChange}
                    />
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      className='notification-modal__button'
                    >
                      {!loading ? (
                        'Salvar'
                      ) : (
                        <CircularProgress color='inherit' size={20} />
                      )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </React.Fragment>
      </>
    )
  }
}
