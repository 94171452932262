import React from 'react'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input
} from 'reactstrap'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { CircularProgress } from '@material-ui/core'
import Header from '../../../components/Headers/Header.jsx'
import api from '../../../Services/api'
import SnackBar from '../../../components/Snackbar/index.js'

export default class AddEpisode extends React.Component {
  constructor ({ match }) {
    super()
    this.state = {
      id_season: match.params.season,
      id_serie: match.params.serie,
      name: '',
      sinopse: EditorState.createEmpty(),
      duration: '',
      poster: '',
      posterUrl: '',
      number: '',
      url: '',
      loading: false
    }
  }

  handleInputChange = e => {
    const { name, value } = e.target

    if (e.target.files) {
      const picture = e.target.files[0]
      this.setState({ poster: picture })
    } else {
      this.setState(() => ({ [name]: value }))
    }
  }

  onEditorStateChange = sinopse => {
    this.setState({
      sinopse
    })
  }

  submitForm = e => {
    e.preventDefault()
    const {
      id_serie,
      id_season,
      name,
      sinopse,
      duration,
      posterUrl,
      number,
      url
    } = this.state
    const { onError } = this.props
    if (!name || !sinopse || !duration || !posterUrl || !number || !url) {
      onError()
      return
    }
    this.setState({ loading: true })
    const decoded = draftToHtml(convertToRaw(sinopse.getCurrentContent()))

    api
      .post('/episodios/inserir', {
        id_serie: id_serie,
        id_season: id_season,
        title: name,
        sinopse: decoded,
        duration: duration,
        number: number,
        poster: posterUrl,
        url: url
      })
      .then(response => {
        if(response.data.status){
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Episodio adicionado com sucesso!",
            snackVariant: "success"
          });
          setTimeout(window.location.reload(), 2000)
        }
        else{
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Confira os dados!",
            snackVariant: "warning"
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error'
        })
      })
  }

  uploadPoster = () => {
    const image = this.state.poster;
    const data = new FormData();
    data.append("image", image);
    data.append("locale", "poster");
    api
      .post("imagens/inserir", data)
      .then(resp => {
        const data = resp.data;
        if (data.status) {
          this.setState({ posterUrl: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Erro de uploading",
            snackVariant: "error"
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: "error"
        });
      });
  }

  render () {
    const { loading } = this.state

    return (
      <>
        <React.Fragment>
          <Header />
          <Container className='mt--7' fluid>
            <Row>
              <div className='col'>
                <Card className='shadow'>
                  <CardHeader className='border-0'>
                    <h3 className='mb-0'>Episódio</h3>
                  </CardHeader>
                  <form
                    className='modal-body'
                    onSubmit={this.submitForm}
                    method='post'
                  >
                    <Label>Nome do Episódio</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Nome do Episódio'
                      value={this.state.name}
                      name='name'
                      type='name'
                      onChange={this.handleInputChange}
                    />
                    <Label>Número do Episódio</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Número do Episódio'
                      value={this.state.number}
                      name='number'
                      type='number'
                      onChange={this.handleInputChange}
                    />
                    <Label>Sinopse</Label>
                    <Editor
                      editorState={this.state.sinopse}
                      placeholder='Sinopse'
                      className='addInput_field_textArea'
                      wrapperClassName='demo-wrapper'
                      editorClassName='demo-editor'
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    <Label>Duração do Episódio</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Duração do Episódio'
                      value={this.state.duration}
                      name='duration'
                      type='time'
                      onChange={this.handleInputChange}
                    />
                    <Label>Url do Episódio</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Nome do Episódio'
                      value={this.state.url}
                      name='url'
                      type='text'
                      onChange={this.handleInputChange}
                    />
                    <Label>Poster</Label>
                    <br />
                    <Label
                      for='poster'
                      className='py-2 px-3 btn btn-primary btn-sm'
                      size='sm'
                    >
                      Adicionar poster
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className='addInput_field'
                      name='poster'
                      type='file'
                      id='poster'
                      onChange={this.handleInputChange}
                    />
                    <Button
                      color='primary'
                      className='py-2 px-3'
                      size='sm'
                      onClick={this.uploadPoster}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                        <CircularProgress color='inherit' size={20} />
                      )}
                    </Button>
                    {this.state.posterUrl === '' ||
                    this.state.posterUrl === null ? (
                      <div>
                          <img
                          style={{ display: 'none' }}
                          alt=''
                          className='img-thumbnail'
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={this.state.posterUrl}
                            alt=''
                            className='img-thumbnail'
                          />
                        </div>
                      )}
                    <br />
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      className='notification-modal__button'
                    >
                      {!loading ? (
                        'Salvar'
                      ) : (
                        <CircularProgress color='inherit' size={20} />
                      )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </React.Fragment>
      </>
    )
  }
}
