import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import api from '../../../Services/api';
import Header from '../../../components/Headers/Header';
import FilmList from '../../../components/FilmList';
import SearchFilm from '../../../components/SearchFilm';
import SnackBar from '../../../components/Snackbar';

class Movies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingSearch: false,
      search: null,
      reqSuccess: false,
      snack: false,
      snackMessage: null,
      snackVariant: null,
    };
  }

  search = (e) => {
    e.preventDefault();
    this.setState({
      search: e.target.search.value,
      reqSuccess: null,
      loading: true,
    });
    this.getMovies(e.target.search.value, { field: 'title', dir: 'asc' });
  }

  getMovies = (search, { field, dir }) => {
    this.setState({ loadingSearch: true });
    api
      .post(`filmes/pesquisar?order_by=${field}&dir=${dir}`, {
        filter: search,
      })
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({
            reqSuccess: data.response.message,
            loading: false,
            loadingSearch: false,
          });
        } else {
          this.setState({
            loading: false,
            loadingSearch: false,
            snack: true,
            snackMessage: 'Filme não encontrado!',
            snackVariant: 'error',
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingSearch: false,
          snack: true,
          snackMessage: `Erro de comunicacão com servidor : ${err}`,
          snackVariant: 'error',
        });
      });
  }

  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Filmes</h3>
                </CardHeader>
                <Form
                  className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex"
                  onSubmit={this.search}
                >
                  <FormGroup className="mb-0">
                    <InputGroup
                      className="input-group-alternative"
                      style={{
                        backgroundColor: 'white',
                        marginLeft: '20px',
                        marginBottom: '20px',
                      }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" style={{ color: 'black' }} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Pesquisar"
                        name="search"
                        type="text"
                        style={{ color: 'black' }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
                {this.state.loading ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'space-around',
                      paddingBottom: 40,
                      textAlign: 'center',
                    }}
                  >
                    <i className="fa fa-spinner fa-pulse" />
                  </div>
                ) : this.state.reqSuccess ? (
                  <SearchFilm
                    movie={this.state.reqSuccess}
                    searchTerm={this.state.search}
                    loadingSearch={this.state.loadingSearch}
                    setOrderBy={this.getMovies}
                  />
                ) : (
                  <FilmList />
                )}

                <CardFooter className="py-4">
                  <nav aria-label="..." />
                  <Link to="/admin/filmes/adicionar">
                    <Button color="primary" className="py-2 px-3" size="sm">
                      Adicionar Filme
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <SnackBar
          isOpen={this.state.snack}
          isVariant={this.state.snackVariant}
          isMessage={this.state.snackMessage}
          isClose={() => this.setState({ snack: false })}
        />
      </>
    );
  }
}

export default Movies;
