import React from 'react'
import {
  FormGroup,
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Label
} from 'reactstrap'
import SnackBar from "../../components/Snackbar/index.js";
import api from '../../Services/api'

export default function EmailModal ({ data, open, onClose }) {
  const [snackbar, setSnack] = React.useState({
    open: false,
    variant: null,
    message: null
  })

  function sendEmail (e) {
    e.preventDefault()
    const { title, body} = e.target
    if (
      !title.value ||
      !body.value
    ) {
      setSnack({
        open: true,
        variant: 'error',
        message: 'Por favor, preencha todos os dados para continuar!'
      })
    } else {
      api.post('/usuarios/email', {
        name: data.fullname,
        email: data.email,
        title: title.value,
        message: body.value,
      })
        .then(resp => {
          if (resp.data.status) {
            this.setState({
              snack: true,
              snackMessage: "Email enviado com sucesso",
              snackVariant: "error"
            });
            setTimeout(() => onClose(), 4000)
          } else {
            setSnack({
                snack: true,
                snackMessage: "Houve algum erro desconhecido no envio do email",
                snackVariant: "error"
              });
          }
        })
        .catch(error => {
          setSnack({
            open: true,
            variant: 'error',
            message: `Houve um problema de conexão, código : ${error}`
          })
        })
    }
  }

  return (
    <>
      <Modal
        className='modal-dialog-centered col-lg'
        isOpen={open}
        toggle={onClose}
        size='lg'
      >
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            Enviar e-mail
          </h5>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={onClose}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className='modal-body'>
          <Form onSubmit={sendEmail}>

            <Row>
              <Col md='12'>
                <Label className='form-control-label' htmlFor='input-value'>
                  Titulo
                </Label>
                <FormGroup>
                  <Input
                    className='form-control-alternative'
                    id='value'
                    type='text'
                    name='title'
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <Label className='form-control-label' htmlFor='input-stock'>
                  Mensagem
                </Label>
                <FormGroup>
                  <Input
                    className='form-control-alternative'
                    id='stock'
                    type='textarea'
                    style={{minHeight:"150px", maxHeight:"300px"}}
                    name='body'
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <div className='modal-footer'>
                  <Button className='my-4' color='primary' type='submit'>
                    Enviar
                  </Button>
                  <Button
                    color='secondary'
                    data-dismiss='modal'
                    type='button'
                    onClick={onClose}
                  >
                    Fechar
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      {snackbar.open && (
        <SnackBar
          isOpen={snackbar.open}
          isVariant={snackbar.variant}
          isMessage={snackbar.message}
          isClose={() =>
            setSnack({
              open: false
            })
          }
        />
      )}
    </>
  )
}
