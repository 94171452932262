import React from 'react'
import { Link } from 'react-router-dom'
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from 'reactstrap'
import { logout, getUsername } from '../../Services/auth.js'

class AdminNavbar extends React.Component {
  render () {
    return (
      <>
        <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
          <Container fluid>
            <Link
              className='h4 mb-0 text-white text-uppercase d-none d-lg-inline-block'
              to='/'
            >
              {this.props.brandText}
            </Link>
            <Nav className='align-items-center d-none d-md-flex' navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className='pr-0' nav>
                  <Media className='align-items-center'>
                    <Media className='ml-2 d-none d-lg-block'>
                      <span className='mb-0 text-sm font-weight-bold'>
                        {getUsername()}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-arrow' right>
                  <DropdownItem className='noti-title' header tag='div'>
                    <h6 className='text-overflow m-0'>Bem-vindo!</h6>
                  </DropdownItem>
                  <DropdownItem to='/admin/user-profile' tag={Link}>
                    <i className='ni ni-single-02' />
                    <span>Meu perfil</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag={Link} onClick={logout} to='/auth/login'>
                    <i className='ni ni-user-run' />
                    <span>Sair</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default AdminNavbar
