import React from 'react'
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'
import { Modal } from '@material-ui/core'
import Header from '../../../components/Headers/Header.jsx'
import SerieList from '../../../components/SerieList'
import SuccessModal from '../../../components/SuccessModal'
import AlertModal from '../../../components/AlertModal'
import { Link } from 'react-router-dom'
import api from '../../../Services/api'
import SnackBar from '../../../components/Snackbar';

class Series extends React.Component {
  state = {
    successModal: false,
    alertModal: false,
    loading: false,
    removeModal: false,
    search: null,
    reqSuccess: false,
    result: null,
    currentSeason: null,
    snack: false,
    snackMessage: null,
    snackVariant: null,
  }

  buscarId (id) {
    this.setState({
      id: id
    })
    return id
  }

  confirmExclude = e => {
    e.preventDefault()
    api
      .post('/series/deletar', {
        id_sr: this.state.id
      })
      .then(function (response) {
        setTimeout(window.location.reload(), 1000)
      })
      .catch(error => console.log(error))
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    })
  }

  handleInputChange = e => {
    const { name, value } = e.target
    this.setState(() => ({ [name]: value }))
  }

  search = async e => {
    e.preventDefault();
    this.setState({
      search: e.target.search.value,
      reqSuccess: null,
      loading: true,
    });
    const search = e.target.search.value;
    await api
      .post('series/pesquisar', {
        filter: search
      })
      .then(resp => {
        if (resp.data.status) {
          this.setState({ loading: false })
          this.setState({ reqSuccess: true })
          this.setState({ result: resp.data.response.message })
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Serie não encontrada!',
            snackVariant: 'error',
          });
        }
      })
      .catch(error => {
        this.setState({ loading: true })
        this.setState({
          loading: false,
          snackMessage: `Erro de comunicacão com servidor : ${error}`,
          snackVariant: 'error',
        });
        console.log(error)
      })
  }

  render () {
    const { successModal, alertModal, removeModal } = this.state
    return (
      <React.Fragment>
        <Header />
        <Container className='mt--7' fluid>
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>Séries</h3>
                </CardHeader>
                <Form
                  className='navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex'
                  onSubmit={this.search}
                >
                  <FormGroup className='mb-0'>
                    <InputGroup className='input-group-alternative' style={{backgroundColor:"white", marginLeft:"20px", marginBottom:"20px"}}>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='fas fa-search' style={{color:"black"}}/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Pesquisar"
                        name="search"
                        type="text"
                        style={{ color: 'black' }}
                        onChange={this.handleInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
                {this.state.result ? (
                  <Table className='align-items-center table-flush' responsive>
                    <thead className='thead-light'>
                      <tr>
                        <th scope='col'>Título da Série</th>
                        <th scope='col'>Temporadas</th>
                        <th scope='col'>Data de lançamento</th>
                        <th scope='col'>Poster</th>
                        <th scope='col' />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.loading ? (
                        <i className='fa fa-spinner fa-pulse' />
                      ) : null}
                      {this.state.reqSuccess ? (
                        this.state.result.map((result, key) => {
                          return (
                            <>
                              <tr key={key}>
                                <th scope='row'>
                                  <span className='mb-0 text-sm'>
                                    {result.sr_title}
                                  </span>
                                </th>
                                <td>{result.total_seasons}</td>
                                <td>
                                  {result.launch_date !== '' && result.launch_date !== null
                                    ? result.launch_date
                                      .substr(0, 10)
                                      .split('-')
                                      .reverse()
                                      .join('/')
                                    : (result.launch_date = '')}
                                </td>
                                <td>
                                  {result.sr_poster === '' ||
                                  result.sr_poster === null ? (
                                    <img
                                        className='img-thumbnail'
                                        alt='...'
                                        style={{ display: 'none' }}
                                      />
                                    ) : (
                                      <img
                                        className='img-thumbnail'
                                        alt='...'
                                        src={result.sr_poster}
                                      />
                                    )}
                                </td>
                                <td className="text-right">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      className="btn-icon-only"
                                      href="#"
                                      role="button"
                                      size="sm"
                                      color=""
                                      style={{ color: '#fa6545' }}
                                      onClick={e => e.preventDefault()}
                                    >
                                      <i className='fas fa-ellipsis-v' />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className='dropdown-menu-arrow'
                                      right
                                    >
                                      <Link
                                        to={`/admin/series/editar/${
                                          result.slug
                                        }`}
                                      >
                                        <DropdownItem>Editar</DropdownItem>
                                      </Link>
                                      <DropdownItem
                                        onClick={() => {
                                          this.toggleModal('removeModal')
                                          this.buscarId(result.id_sr)
                                        }}
                                      >
                                        Remover
                                      </DropdownItem>
                                      <DropdownItem>
                                        <Link to={`/admin/serie/${result.id_sr}/temporadas`} style={{color:"black"}}>Ver Temporadas</Link>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            </>
                          )
                        })
                      ) : (
                        <h1 style={{ margin: '20px' }}>
                          {this.state.loading === true
                            ? ''
                            : 'Ainda não existe séries cadastradas!'}
                        </h1>
                      )}
                    </tbody>
                    {removeModal && (
                      <Modal
                        open
                        onClose={() => this.toggleModal('removeModal')}
                      >
                        <div className='alert__content'>
                          <h1 className='alert__title'>Aviso</h1>
                          <h2 className='alert__subheader'>
                            Deseja realmente excluir?
                          </h2>
                          <button
                            className='alert__button'
                            onClick={this.confirmExclude}
                          >
                            Excluir
                          </button>
                        </div>
                      </Modal>
                    )}
                  </Table>
                ) : (
                  <SerieList />
                )}
                <CardFooter className='py-4'>
                  <nav aria-label='...' />
                  <Link to='/admin/series/adicionar'>
                    <Button color='primary' className='py-2 px-3' size='sm'>
                      Adicionar Série
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </div>
          </Row>

          <SuccessModal
            show={successModal}
            onClick={() => this.toggleModal('successModal')}
          />

          <AlertModal
            show={alertModal}
            onClick={() => this.toggleModal('alertModal')}
            title='Preencha todos os campos'
            subheader='É necessário informar todos os dados para cadastrar uma nova Série!'
          />
        </Container>
        <SnackBar
          isOpen={this.state.snack}
          isVariant={this.state.snackVariant}
          isMessage={this.state.snackMessage}
          isClose={() => this.setState({ snack: false })}
        />
      </React.Fragment>
    )
  }
}

export default Series
