import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Label,
  Input
} from 'reactstrap'
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import api from './../../../Services/api'
import Header from '../../../components/Headers/Header.jsx'
import { CircularProgress } from '@material-ui/core'
import SnackBar from '../../../components/Snackbar/index.js'

export default class EditCast extends Component {
  constructor({ match }) {
    super()
    this.state = {
      new_id: match.params.id,
      loading: false,
      id: '',
      title: '',
      text: '',
      cover: '',
      coverUrl: '',
      editorState: ''
    }
  }

  componentDidMount() {
    this.getCastData()
  }

  getCastData = async () => {
    await api
      .get(`/noticias/${this.state.new_id}/`)
      .then(resp => {
        const data = resp.data
        if (data.status) {
          const result = data.response.message
          this.setState({
            id: result.id,
            title: result.title,
            text: result.text,
            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(`${result.text}`)
              )
            ),
            cover: result.image,
            coverUrl: result.image
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleInputChange = e => {
    const { name, value } = e.target

    if (e.target.files) {
      const picture = e.target.files[0]
      this.setState({ cover: picture })
    } else {
      this.setState(() => ({ [name]: value }))
    }
  }

  uploadCover = () => {
    const image = this.state.cover
    const data = new FormData()
    data.append('image', image)
    data.append('locale', 'poster')
    api
      .post('imagens/inserir', data)
      .then(resp => {
        const data = resp.data
        if (data.status) {
          this.setState({ cover: data.response.message })
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Erro de uploading',
            snackVariant: 'error'
          })
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: 'error'
        })
      })
  }

  removeCover = image => {
    this.setState({ cover: null });
    this.setState({ coverUrl: null });
    api
      .post("imagens/deletar", { exclude: image, locale: "poster" })
      .then(response => {
      })
      .catch(error => {
      });
  };

  onEditorStateChange = text => {
    this.setState({
      text
    })
  }

  submitForm = e => {
    e.preventDefault()
    const news = { ...this.state }
    let decoded = []

    if (!news.title || !news.text || !news.cover) {
      this.setState({
        snack: true,
        snackMessage: "Prrencha todos os dados necessários.",
        snackVariant: "error"
      });
    }
    else {
      if (typeof news.text === 'object') {
        decoded = draftToHtml(convertToRaw(news.text.getCurrentContent()))
      } else {
        decoded = news.text
      }
      this.setState({ loading: true })
      api
        .post('/noticias/editar', {
          id: news.id,
          title: news.title,
          text: decoded,
          image: news.cover
        })
        .then(response => {
          if (response.data.status) {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: "Noticia editada com sucesso!",
              snackVariant: "success"
            });
            setTimeout(window.location.reload(), 4000);
          }
          else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: "Confira os dados!",
              snackVariant: "warning"
            });
          }
        })
        .catch(function (error) {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: 'error'
          })
        })
    }
  }

  render() {
    const { loading } = this.state.loading
    return (
      <>
        <React.Fragment>
          <Header />
          <Container className='mt--7' fluid>
            <Row>
              <div className='col'>
                <Card className='shadow'>
                  <CardHeader className='border-0'>
                    <h3 className='mb-0'>Editar Notícia</h3>
                  </CardHeader>
                  <form
                    className='modal-body'
                    onSubmit={this.submitForm}
                    method='post'
                  >
                    <Label>Título da notícia</Label>
                    <Input
                      className='addInput_field'
                      value={this.state.title}
                      name='title'
                      type='name'
                      onChange={this.handleInputChange}
                    />
                    <Label>Conteúdo</Label>
                    {this.state.editorState && (
                      <Editor
                        defaultEditorState={this.state.editorState}
                        placeholder='Conteúdo'
                        className='addInput_field_textArea'
                        wrapperClassName='demo-wrapper'
                        editorClassName='demo-editor'
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    )}
                    <Label>Capa</Label>
                    {this.state.cover === '' || this.state.cover === null ? (
                      <div>
                        <img
                          style={{ display: 'none' }}
                          alt=''
                          className='img-thumbnail'
                        />
                        <Button
                          color='primary'
                          className='py-2 px-3 small'
                          style={{ display: 'none' }}
                          size='sm'
                          onClick={() => this.removeCover(this.state.cover)}
                        >
                          Remover
                        </Button>
                      </div>
                    ) : (
                        <div>
                          <img
                            src={this.state.cover}
                            alt=''
                            className='img-thumbnail'
                          />
                          <Button
                            color='primary'
                            className='py-2 px-3 small'
                            size='sm'
                            onClick={() => this.removeCover(this.state.cover)}
                          >
                            Remover
                        </Button>
                        </div>
                      )}
                    <Label
                      for='newCover'
                      className='py-2 px-3 btn btn-primary btn-sm'
                      size='sm'
                    >
                      Adicionar capa
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className='addInput_field'
                      name='newCover'
                      type='file'
                      id='newCover'
                      onChange={this.handleInputChange}
                    />
                    <br />
                    <Button
                      color='primary'
                      className='py-2 px-3'
                      size='sm'
                      onClick={this.uploadCover}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                    <br />
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      className='notification-modal__button'
                    >
                      {!loading ? (
                        'Salvar'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </React.Fragment>
      </>
    )
  }
}
