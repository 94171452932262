import React from 'react'
import { CircularProgress } from '@material-ui/core'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import Header from '../../../components/Headers/Header.jsx'
import { storage } from '../../../firebase'
import api from '../../../Services/api'
import axios from 'axios'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Label,
  Input
} from 'reactstrap'
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import SnackBar from '../../../components/Snackbar/index.js'
import Slugfier from "../../../functions/Slugfier.jsx"

const options = [{ value: 1, label: 'Sim' }, { value: 0, label: 'Não' }]

export default class AddMovie extends React.Component {
  state = {
    name: null,
    total_seasons: null,
    sinopse: EditorState.createEmpty(),
    classification: null,
    directors: null,
    languages: null,
    categories: null,
    data: null,
    serieLink: null,
    imdbLink: null,
    trailer: null,
    palavrasChave: null,
    loading: false,
    poster: null,
    posterUrl: null,
    banner: null,
    bannerUrl: null,
    gallery: null,
    galleryUrl: null,
    image: null,
    category: null,
    categoryReq: [],
    indication: null,
    indicationReq: [],
    language: null,
    languageReq: [],
    director: null,
    directorReq: [],
    country: null,
    countryReq: [],
    newCategory: null,
    newLanguage: null,
    newDirector: null,
    newCountry: null,
    newIndication: 1,
    commingsoon: null,
    newCommingsoon: 1,
    omdb: false,
    snack: false,
    snackVariant: null,
    snackMessage: null,
    slugStatus: false,
    slugLoading: false,
    location_price : 0,
  }
  componentDidMount() {
    this.categoryRequest()
    this.countryRequest()
    this.classificationRequest()
    this.languageRequest()
    this.directorRequest()
  }

  categoryRequest = () => {
    api
      .post('/categorias')
      .then(res => {
        this.setState({ categoryReq: res.data.response.message })
      })
      .catch(error => console.log(error))
    this.setState({
      categoryReq: [...this.state.categoryReq]
    })
  }

  countryRequest = () => {
    api
      .post('/paises')
      .then(res => {
        this.setState({ countryReq: res.data.response.message })
      })
      .catch(error => console.log(error))
    this.setState({
      countryReq: [...this.state.countryReq]
    })
  }

  classificationRequest = () => {
    api
      .post('/classificacoes')
      .then(res => {
        this.setState({ indicationReq: res.data.response.message })
      })
      .catch(error => console.log(error))
    this.setState({
      indicationReq: [...this.state.indicationReq]
    })
  }

  languageRequest = () => {
    api
      .post('/idiomas')
      .then(res => {
        this.setState({ languageReq: res.data.response.message })
      })
      .catch(error => console.log(error))
    this.setState({
      languageReq: [...this.state.languageReq]
    })
  }

  directorRequest = () => {
    api
      .post('/elenco')
      .then(res => {
        this.setState({ directorReq: res.data.response.message })
      })
      .catch(error => console.log(error))
    this.setState({
      directorReq: [...this.state.directorReq]
    })
  }

  handleChange = classification => {
    let newdata = []
    this.setState({ newIndication: classification.label })
    this.setState({ classification })
  }

  handleDirectorChange = directors => {
    let newdata = []
    if (directors === null) {
      directors = 0
    }
    for (let i = 0; i < directors.length; i++) {
      newdata.push({ director: directors[i].label })
    }

    this.setState({ newDirector: newdata })
    this.setState({ directors })
  }

  handleLanguageChange = languages => {
    let newdata = []
    if (languages === null) {
      languages = 0
    }
    for (let i = 0; i < languages.length; i++) {
      newdata.push({ languages: languages[i].label })
    }

    this.setState({ newLanguage: newdata })
    this.setState({ languages })
  }

  handleCategoryChange = categories => {
    let newdata = []
    if (categories === null) {
      categories = 0
    }
    for (let i = 0; i < categories.length; i++) {
      newdata.push({ category: categories[i].label })
    }

    this.setState({ newCategory: newdata })
    this.setState({ categories })
  }

  handleCountryChange = countries => {
    let newdata = []
    if (countries === null) {
      countries = 0
    }
    for (let i = 0; i < countries.length; i++) {
      newdata.push({ country: countries[i].label })
    }

    this.setState({ newCountry: newdata })
    this.setState({ countries })
  }

  onEditorStateChange = sinopse => {
    this.setState({
      sinopse
    })
  }

  handleInputChange = e => {
    const { name, value } = e.target

    if (e.target.files) {
      const picture = e.target.files[0]
      this.setState({ poster: picture })
    } else {
      this.setState(() => ({ [name]: value }))
    }
  }

  handleBannerChange = e => {
    const { name, value } = e.target

    if (e.target.files) {
      const picture = e.target.files[0]
      this.setState({ banner: picture })
    } else {
      this.setState(() => ({ [name]: value }))
    }
  }

  handleTitleChange = e => {
    const { value } = e.target;
    const slug = Slugfier(value);

    this.setState({ name: value });
    this.setState({ slug: slug })

  };

  handleSlugChange = e => {
    const { value } = e.target;

    this.setState({ slug: value })
  };

  handleGalleryChange = e => {
    const { name, value } = e.target;

    if (e.target.files) {
      const picture = e.target.files;
      this.setState({ gallery: picture });
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  handleCommingsoonChange = commingsoon => {
    let newdata = []
    newdata.push({ commingsoon: commingsoon.value })
    this.setState({ newCommingsoon: commingsoon.value })
    this.setState({ commingsoon })
  }

  submitForm = e => {
    e.preventDefault()
    const {
      name,
      slug,
      sinopse,
      total_seasons,
      data,
      serieLink,
      imdbLink,
      trailer,
      palavrasChave,
      newCategory,
      newCountry,
      newDirector,
      newLanguage,
      newIndication,
      posterUrl,
      bannerUrl,
      galleryUrl,
      newCommingsoon,
      omdb,
      location_price
    } = this.state
    const { onError } = this.props

    if (!name || !newIndication || !posterUrl || !bannerUrl || !slug) {
      this.setState({
        snack: true,
        snackMessage: 'Preencha todos os dados necessários.',
        snackVariant: 'error'
      })
    } else {
      this.setState({ loading: true })

      const decoded = draftToHtml(convertToRaw(sinopse.getCurrentContent()))

      api
        .post('/series/inserir', {
          title: name,
          slug: slug,
          sinopse: decoded,
          total_seasons: total_seasons,
          ranking: newIndication,
          directors: newDirector,
          categories: newCategory,
          languages: newLanguage,
          countries: newCountry,
          sr_ranking: newIndication,
          sr_director: newDirector,
          sr_category: newCategory,
          sr_languages: newLanguage,
          sr_country: newCountry,
          launch_date: data,
          serie_url: serieLink,
          imdb_url: imdbLink,
          trailer: trailer,
          keywords: palavrasChave,
          poster: posterUrl,
          banner: bannerUrl,
          gallery: galleryUrl !== null ? galleryUrl.join(", ") : galleryUrl,
          commingsoon: newCommingsoon,
          omdb: omdb,
          location_price: location_price
        })
        .then(response => {
          if (response.data.status) {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Serie adicionada com sucesso!',
              snackVariant: 'success'
            })
            setTimeout(() => {window.location.href = '/#/admin/series'}, 2000)
          }
          else if (response.data.response.message === 'Invalid slug') {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: "Slug usado, insira outro!",
              snackVariant: "warning"
            });
          }
          else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: 'Algo deu errado, confira os dados',
              snackVariant: 'warning'
            })
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: 'error'
          })
        })
    }
  }

  uploadPoster = () => {
    const image = this.state.poster;
    const data = new FormData();
    data.append("image", image);
    data.append("locale", "poster");
    api
      .post("imagens/inserir", data)
      .then(resp => {
        const data = resp.data;
        if (data.status) {
          this.setState({ posterUrl: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Erro de uploading",
            snackVariant: "error"
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: "error"
        });
      });
  }

  uploadBanner = () => {
    const image = this.state.banner;
    const data = new FormData();
    data.append("image", image);
    data.append("locale", "banner");
    api
      .post("imagens/inserir", data)
      .then(resp => {
        const data = resp.data;
        if (data.status) {
          this.setState({ bannerUrl: data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: "Erro de uploading",
            snackVariant: "error"
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          snack: true,
          snackMessage: `Erro de conexão : ${error}`,
          snackVariant: "error"
        });
      });
  }

  uploadGallery = () => {
    let urls = [];
    const image = this.state.gallery;
    for (let i = 0; i < image.length; i++) {
      const data = new FormData();
      data.append("image", image[i]);
      data.append("locale", "gallery");
      api
        .post("imagens/inserir", data)
        .then(resp => {
          const data = resp.data;
          if (data.status) {
            urls.push(data.response.message);
            this.setState({ galleryUrl: urls });
          } else {
            this.setState({
              loading: false,
              snack: true,
              snackMessage: "Houve algum erro desconhecido no upload",
              snackVariant: "error"
            });
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: `Erro de conexão : ${error}`,
            snackVariant: "error"
          });
        });
    }
  };

  categorySelect = () => {
    const resultado = this.state.categoryReq.map(
      result =>
        (this.state.category = {
          value: result.id_ct,
          label: result.category
        })
    )
    return resultado
  }

  classificationSelect = () => {
    const resultado = this.state.indicationReq.map(
      result =>
        (this.state.indication = {
          value: result.id_rg,
          label: result.ranking
        })
    )
    return resultado
  }

  languageSelect = () => {
    const resultado = this.state.languageReq.map(
      result =>
        (this.state.language = {
          value: result.id_lg,
          label: result.languages
        })
    )
    return resultado
  }

  directorSelect = () => {
    const resultado = this.state.directorReq.map(
      result =>
        (this.state.director = {
          value: result.id_actor,
          label: result.fullname,
        })
    )
    return resultado
  }

  countrySelect = () => {
    const resultado = this.state.countryReq.map(
      result =>
        (this.state.country = {
          value: result.id_cy,
          label: result.country
        })
    )
    return resultado
  }

  handleDirectorList = debounce((value, callback) => {
    if (value === '') return;
    api
      .post('/elenco/pesquisar', { filter: value })
      .then((res) => {
        if (res.data.status) {
          this.setState({ directorReq: res.data.response.message });
          callback(res.data.response.message.map(el => ({ value: el.id_dr, label: `${el.fullname} (${el.slug})` })));
        }
      })
      .catch(error => console.log(error));
  }, 1000);

  getGalleryUrl(string) {
    if (string) {
      return string.map(string => (
        <div className="block__images">
          <img src={string} alt="" className="img__modal" />
          <Button
            color="primary"
            className="py-2 px-3 small button__img"
            size="medium"
            onClick={() => this.removeGallery(string)}
          >
            Remover
          </Button>
        </div>
      ));
    }
    return (<></>);
  }

  removePoster = image => {
    this.setState({ poster: null });
    this.setState({ posterUrl: null });
    api
        .post("imagens/deletar", {exclude: image, locale: "poster"})
        .then(response => {
        })
        .catch(error => {
        });
  };
  
  removeBanner = image => {
    this.setState({ banner: null });
    this.setState({ bannerUrl: null });
    api
        .post("imagens/deletar", {exclude: image, locale: "banner"})
        .then(response => {
        })
        .catch(error => {
        });
  };

  removeGallery(image){
    const links = this.state.galleryUrl

    function check(link) {
      return link === image;
    }

    let index = links.findIndex(check);
    links.splice(index, 1);
    this.setState({ galleryUrl: links});
    api
        .post("imagens/deletar", {exclude: image, locale: "gallery"})
        .then(response => {
        })
        .catch(error => {
        });
  };

  searchSerie = () => {
    const { name } = this.state
    this.setState({ omdb: true })
    this.setState({ loading: true })
    axios
      .get(`https://www.omdbapi.com/?apikey=a81c6601&t=${name}`)
      .then(res => {
        const lang = res.data.Language.split(', ')
        const direc = res.data.Actors.split(', ')
        const genre = res.data.Genre.split(', ')
        const countr = res.data.Country.split(', ')
        let newArray = []
        let newArray2 = []
        /** Atores */
        for (let i = 0; i < direc.length; i++) {
          newArray.push({ label: direc[i], value: direc[i] })
        }
        for (let i = 0; i < direc.length; i++) {
          newArray2.push({ director: direc[i] })
        }
        this.setState({ newDirector: newArray2 })
        this.setState({ directors: newArray })
        newArray = []
        newArray2 = []
        /** Classificação */
        for (let i = 0; i < genre.length; i++) {
          newArray.push({ label: genre[i], value: genre[i] })
        }
        for (let i = 0; i < genre.length; i++) {
          newArray2.push({ category: genre[i] })
        }
        this.setState({ newCategory: newArray2 })
        this.setState({ categories: newArray })
        newArray = []
        newArray2 = []
        /** Linguagem */
        for (let i = 0; i < lang.length; i++) {
          newArray.push({ label: lang[i], value: lang[i] })
        }
        for (let i = 0; i < lang.length; i++) {
          newArray2.push({ languages: lang[i] })
        }
        this.setState({ newLanguage: newArray2 })
        this.setState({ languages: newArray })
        newArray = []
        newArray2 = []
        /** Países */
        for (let i = 0; i < countr.length; i++) {
          newArray.push({ label: countr[i], value: countr[i] })
        }
        for (let i = 0; i < countr.length; i++) {
          newArray2.push({ country: countr[i] })
        }
        this.setState({ newCountry: newArray2 })
        this.setState({ countries: newArray })

        const e={
          target:{
            value: res.data.Title
          }

        }
        this.handleTitleChange(e);

        this.setState({
          sinopse: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(`<p>${res.data.Plot}</p>`)
            )
          )
        })
        this.setState({ total_seasons: parseInt(res.data.totalSeasons) })
        this.setState({ posterUrl: res.data.Poster })
        this.setState({
          imdbLink: `https://www.imdb.com/title/${res.data.imdbID}`
        })
        this.setState({ loading: false })
      })
      .catch(error => {
        console.log(error)
        this.setState({ loading: false })
      })
  }

  render() {
    const {
      loading,
      classification,
      countries,
      directors,
      languages,
      categories,
      sinopse,
      commingsoon,
      highlighted
    } = this.state
    return (
      <>
        <React.Fragment>
          <Header />
          <Container className='mt--7' fluid>
            <Row>
              <div className='col'>
                <Card className='shadow'>
                  <CardHeader className='border-0'>
                    <h3 className='mb-0'>Séries</h3>
                  </CardHeader>
                  <form
                    className='modal-body'
                    onSubmit={this.submitForm}
                    method='post'
                  >
                    <h1 className='notification-modal__heading'>
                      Adicionar nova Série
                    </h1>
                    <Label>Nome da série</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Nome da serie'
                      value={this.state.name}
                      name='name'
                      type='name'
                      onChange={this.handleTitleChange}
                    />
                    <Button
                      color='primary'
                      className='py-2 px-3'
                      size='sm'
                      onClick={this.searchSerie}
                    >
                      {!loading ? (
                        'Buscar'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                    <br />
                    <Label>Slug</Label>
                    <Input
                      className="addInput_field"
                      placeholder="Slug"
                      name="slug"
                      value={this.state.slug}
                      type="name"
                      onChange={this.handleSlugChange}
                    />
                    <br />
                    <Label>Sinopse</Label>
                    <Editor
                      editorState={this.state.sinopse}
                      placeholder='Sinopse'
                      className='addInput_field_textArea'
                      wrapperClassName='demo-wrapper'
                      editorClassName='demo-editor'
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    <Label>Número de temporadas</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Número de temporadas'
                      value={this.state.total_seasons}
                      name='total_seasons'
                      type='number'
                      onChange={this.handleInputChange}
                    />
                    <Label>Classificação</Label>
                    <Select
                      className='addInput_field'
                      placeholder='Classificação'
                      value={classification}
                      onChange={this.handleChange}
                      options={this.classificationSelect()}
                    />
                    <Label>Elenco</Label>
                    <AsyncSelect
                      className="addInput_field"
                      placeholder="Elenco"
                      isMulti
                      value={directors}
                      cacheOptions
                      defaultOptions={this.directorSelect()}
                      loadOptions={this.handleDirectorList}
                      onChange={this.handleDirectorChange}
                    />
                    <Label>Idiomas</Label>
                    <Select
                      className='addInput_field'
                      placeholder='Idiomas'
                      isMulti
                      value={languages}
                      onChange={this.handleLanguageChange}
                      options={this.languageSelect()}
                    />
                    <Label>Categorias</Label>
                    <Select
                      className='addInput_field'
                      placeholder='Categorias'
                      isMulti
                      value={categories}
                      onChange={this.handleCategoryChange}
                      options={this.categorySelect()}
                    />
                    <Label>Países</Label>
                    <Select
                      className='addInput_field'
                      placeholder='Países'
                      isMulti
                      value={countries}
                      onChange={this.handleCountryChange}
                      options={this.countrySelect()}
                    />
                    <Label>Data de lançamento</Label>
                    <Input
                      className='addInput_field'
                      value={this.state.data}
                      name='data'
                      type='date'
                      onChange={this.handleInputChange}
                    />
                    <Label>Link da serie</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Link da serie'
                      value={this.state.serieLink}
                      name='serieLink'
                      type='name'
                      onChange={this.handleInputChange}
                    />
                    <Label>Link IMDB</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Link IMDB'
                      value={this.state.imdbLink}
                      name='imdbLink'
                      type='name'
                      onChange={this.handleInputChange}
                    />
                    <Label>Trailer</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Trailer'
                      value={this.state.trailer}
                      name='trailer'
                      type='name'
                      onChange={this.handleInputChange}
                    />
                    <Label>Palavras Chave</Label>
                    <Input
                      className='addInput_field'
                      placeholder='Palavras chave'
                      value={this.state.palavrasChave}
                      name='palavrasChave'
                      type='name'
                      onChange={this.handleInputChange}
                    />
                    <Label>Em Breve</Label>
                    <Select
                      className='addInput_field'
                      placeholder='Em Breve'
                      value={commingsoon}
                      onChange={this.handleCommingsoonChange}
                      options={options}
                    />
                    <Label>Poster</Label>
                    <br />
                    <Label
                      for='poster'
                      className='py-2 px-3 btn btn-primary btn-sm'
                      size='sm'
                    >
                      Adicionar poster
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className='addInput_field'
                      name='poster'
                      type='file'
                      id='poster'
                      onChange={this.handleInputChange}
                    />
                    <br />
                    <Button
                      color='primary'
                      className='py-2 px-3'
                      size='sm'
                      onClick={this.uploadPoster}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                    {this.state.posterUrl === null ? (
                      <div>
                        <img
                          style={{ display: 'none' }}
                          alt=''
                          className='img-thumbnail'
                        />
                      </div>
                    ) : (
                        <div>
                          <img
                            src={this.state.posterUrl}
                            alt=''
                            className='img-thumbnail'
                          />
                          <Button
                            color="primary"
                            className="py-2 px-3 btn btn-primary btn-sm"
                            size="sm"
                            onClick={() => this.removePoster(this.state.posterUrl)}
                          >
                            Remover
                          </Button>
                        </div>
                      )}
                    <Label>Banner</Label>
                    <br />
                    <Label
                      for='banner'
                      className='py-2 px-3 btn btn-primary btn-sm'
                      size='sm'
                    >
                      Adicionar banner
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className='addInput_field'
                      name='banner'
                      type='file'
                      id='banner'
                      onChange={this.handleBannerChange}
                    />
                    <br />
                    <Button
                      color='primary'
                      className='py-2 px-3'
                      size='sm'
                      onClick={this.uploadBanner}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                    {this.state.bannerUrl === null ? (
                      <div>
                        <img
                          src={this.state.bannerUrl}
                          style={{ display: 'none' }}
                          alt=''
                          className='img-thumbnail'
                        />
                      </div>
                    ) : (
                        <div>
                          <img
                            src={this.state.bannerUrl}
                            alt=''
                            className='img-thumbnail'
                          />
                          <Button
                            color="primary"
                            className="py-2 px-3 btn btn-primary btn-sm"
                            size="sm"
                            onClick={() => this.removeBanner(this.state.bannerUrl)}
                          >
                            Remover
                          </Button>
                        </div>
                      )}
                    <Label>Galeria de imagens</Label>
                    <br />
                    <Label
                      for='gallery'
                      className='py-2 px-3 btn btn-primary btn-sm'
                      size='sm'
                    >
                      Adicionar galeria de imagens
                    </Label>
                    <Input
                      style={{ display: 'none' }}
                      className='addInput_field'
                      name='gallery[]'
                      type='file'
                      multiple
                      id='gallery'
                      onChange={this.handleGalleryChange}
                    />
                    <br />
                    <Button
                      color='primary'
                      className='py-2 px-3'
                      size='sm'
                      onClick={this.uploadGallery}
                    >
                      {!loading ? (
                        'Upload'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                    <div
                      className="div"
                      style={{ display: !this.state.galleryUrl && "none", paddingBottom: 50 }}
                    >
                      {this.getGalleryUrl(this.state.galleryUrl)}
                    </div>
                    <br />
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      className='notification-modal__button'
                    >
                      {!loading ? (
                        'Salvar'
                      ) : (
                          <CircularProgress color='inherit' size={20} />
                        )}
                    </Button>
                  </form>
                </Card>
              </div>
            </Row>
            <SnackBar
              isOpen={this.state.snack}
              isVariant={this.state.snackVariant}
              isMessage={this.state.snackMessage}
              isClose={() => this.setState({ snack: false })}
            />
          </Container>
        </React.Fragment>
      </>
    )
  }
}
