import React, { Component } from "react";
import {
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import { Modal } from "@material-ui/core";
import api from "../../Services/api";
import SuccessModal from "../../components/SuccessModal";
import AlertModal from "../../components/AlertModal";
import htmlToText from "html-to-text";
import { Link } from 'react-router-dom'

let prev = 0;
let last = 0;
class NewsList extends Component {
  constructor() {
    super();
    this.state = {
      requests: [],
      currentNews: null,
      currentPage: 1,
      todosPerPage: 5,
      removeModal: false,
      successModal: false,
      alertModal: false,
      reqSuccess: false,
      loading: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleLastClick = this.handleLastClick.bind(this);
    this.handleFirstClick = this.handleFirstClick.bind(this);
  }

  buscarId(id) {
    this.setState({
      id: id
    });
    return id;
  }

  confirmExclude = e => {
    e.preventDefault();
    api
      .post("/noticias/deletar", {
        id: this.state.id
      })
      .then(function(response) {
        setTimeout(window.location.reload(), 1000);
      })
      .catch(error => console.log(error));
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await api
      .post("/noticias")
      .then(res => {
        this.setState({
          reqSuccess: res.data.status,
          requests: res.data.response.message
        });
        this.setState({ loading: true });
      })
      .catch(error => console.log(error));
    this.setState({ loading: false });
    this.setState({
      requests: [...this.state.requests]
    });
    this.setState({ loading: false });
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({
      currentPage: Number(e.target.id)
    });
  }

  handleLastClick(e) {
    e.preventDefault();
    this.setState({
      currentPage: last
    });
  }

  handleFirstClick(e) {
    e.preventDefault();
    this.setState({
      currentPage: 1
    });
  }

  toggleModal = modal => {
    this.setState(state => ({
      [modal]: !state[modal]
    }));
  };

  render() {
    let {
      requests,
      currentPage,
      todosPerPage,
      removeModal,
      successModal,
      alertModal,
      currentNews
    } = this.state;

    let indexOfLastTodo = currentPage * todosPerPage;
    let indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    let currentTodos = requests.slice(indexOfFirstTodo, indexOfLastTodo);
    prev = currentPage > 0 ? currentPage - 1 : 0;
    last = Math.ceil(requests.length / todosPerPage);

    let pageNumbers = [];
    for (let i = 1; i <= last; i++) {
      pageNumbers.push(i);
    }

    const convertTextNews = text => {
      return text.replace(/(<([^>]+)>)/gi, '').slice(0,30) + '...';
    };

    return (
      <>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Título</th>
              <th scope="col">Conteúdo</th>
              <th scope="col">Capa</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {this.state.loading ? <i className="fa fa-spinner fa-pulse" /> : null}
            {this.state.reqSuccess ? (
              currentTodos.map((result, key) => {
                return (
                  <>
                    <tr key={key}>
                      <th scope="row">
                        <span className="mb-0 text-sm">{result.title}</span>
                      </th>
                      <td dangerouslySetInnerHTML={{ __html: convertTextNews(result.text) }}>
                        {/* {teste(result.text)} */}
                        {/* {htmlToText.fromString(`${result.text.slice(0,30)}...`, {
                          wordwrap: 130
                        })} */}
                      </td>
                      <td>
                        {result.image === "" || result.image === null ? (
                          <img
                            className="img-thumbnail"
                            alt="..."
                            style={{ display: "none" }}
                          />
                        ) : (
                          <img
                            className="img-thumbnail"
                            alt="..."
                            src={result.image}
                          />
                        )}
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            style={{ color: '#fa6545' }}
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <Link to={`/admin/noticias/editar/${result.id}`}>
                              <DropdownItem>Editar</DropdownItem>
                            </Link>
                            <DropdownItem
                              onClick={() => {
                                this.toggleModal("removeModal");
                                this.buscarId(result.id);
                              }}
                            >
                              Remover
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <h1 style={{ margin: "20px" }}>
                {this.state.loading === true
                  ? ""
                  : "Ainda não existe notícias cadastradas!"}
              </h1>
            )}
          </tbody>
          {removeModal && (
            <Modal
              open
              onClose={() => this.toggleModal("removeModal")}
              onSuccess={() => {
                this.toggleModal("removeModal");
                this.toggleModal("successModal");
              }}
              onError={() => this.toggleModal("alertModal")}
            >
              <div className="alert__content">
                <h1 className="alert__title">Aviso</h1>
                <h2 className="alert__subheader">Deseja realmente excluir?</h2>
                <button className="alert__button" onClick={this.confirmExclude}>
                  Excluir
                </button>
              </div>
            </Modal>
          )}

          <SuccessModal
            show={successModal}
            onClick={() => this.toggleModal("successModal")}
          />

          <AlertModal
            show={alertModal}
            onClick={() => this.toggleModal("alertModal")}
            title="Preencha todos os campos"
            subheader="É necessário informar todos os dados para editar uma programação!"
          />
        </Table>
        <ul id="page-numbers">
          <nav>
            <Pagination size="sm" style={{ display: 'flex', justifyContent: 'center' }}>
              <PaginationItem>
                {prev === 0 ? (
                  <PaginationLink disabled>{"<<"}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleFirstClick}
                    id={prev}
                    href={prev}
                  >
                    {"<<"}
                  </PaginationLink>
                )}
              </PaginationItem>
              <PaginationItem>
                {prev === 0 ? (
                  <PaginationLink disabled>{"<"}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleClick}
                    id={prev}
                    href={prev}
                  >
                    {"<"}
                  </PaginationLink>
                )}
              </PaginationItem>
              {pageNumbers.map((number, i) => (
                number >= currentPage - 4
                && number <= currentPage + 4 ? (
                  <Pagination key={i}>
                    <PaginationItem
                      active={pageNumbers[currentPage - 1] === number}
                    >
                      <PaginationLink
                        onClick={this.handleClick}
                        href={number}
                        key={number}
                        id={number}
                      >
                        {number}
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                  ) : null
              ))}
              <PaginationItem>
                {currentPage === last ? (
                  <PaginationLink disabled>{">"}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleClick}
                    id={pageNumbers[currentPage]}
                    href={pageNumbers[currentPage]}
                  >
                    {">"}
                  </PaginationLink>
                )}
              </PaginationItem>

              <PaginationItem>
                {currentPage === last ? (
                  <PaginationLink disabled>{">>"}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleLastClick}
                    id={pageNumbers[currentPage]}
                    href={pageNumbers[currentPage]}
                  >
                    {">>"}
                  </PaginationLink>
                )}
              </PaginationItem>
            </Pagination>
          </nav>
        </ul>
      </>
    );
  }
}

export default NewsList;
