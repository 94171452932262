import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import Header from '../../../components/Headers/Header';
import api from '../../../Services/api';

let prev = 0;
let last = 0;
class Historic extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      modal: false,
      redirect: false,
      reqSuccess: false,
      result: '',
      currentPage: 1,
      todosPerPage: 10,
    };
  }

  componentDidMount() {
    this.getHistoric();
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      currentPage: Number(e.target.id),
    });
  }

  handleLastClick = (e) => {
    e.preventDefault();
    this.setState({
      currentPage: last,
    });
  }

  handleFirstClick = (e) => {
    e.preventDefault();
    this.setState({
      currentPage: 1,
    });
  }

  toggleModal = (modal) => {
    this.setState(state => ({
      [modal]: !state[modal],
    }));
  }

  getHistoric = async () => {
    this.setState({ loading: true });
    await api
      .get('registros')
      .then((resp) => {
        if (resp.data.status) {
          this.setState({ loading: false });
          this.setState({ reqSuccess: true });
          this.setState({ result: resp.data.response.message });
        } else {
          this.setState({ loading: true });
          this.setState({ result: null });
        }
      })
      .catch((error) => {
        this.setState({ loading: true });
        console.log(error);
      });
  }

  render() {
    const {
      result,
      currentPage,
      loading,
      reqSuccess,
      todosPerPage,
    } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = result.slice(indexOfFirstTodo, indexOfLastTodo);
    prev = currentPage > 0 ? currentPage - 1 : 0;
    last = Math.ceil(result.length / todosPerPage);

    const pageNumbers = [];
    for (let i = 1; i <= last; i++) {
      pageNumbers.push(i);
    }
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Registros</h3>
                </CardHeader>
                {result ? (
                  <>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: '10%' }}>Nome do usuário</th>
                          <th scope="col">Modificação realizada</th>
                          <th scope="col" style={{ width: '10%' }}>Data da modificação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <i className="fa fa-spinner fa-pulse" />
                        ) : null}
                        {reqSuccess ? (
                          currentTodos.map((todo, index) => (
                            <>
                              <tr key={index}>
                                <td style={{ whiteSpace: 'normal' }}>{todo.name_report}</td>
                                <td style={{ whiteSpace: 'normal' }}>{todo.description_report}</td>
                                <td style={{ whiteSpace: 'normal' }}>
                                  {new Date(todo.date_report)
                                    .toISOString()
                                    .substr(0, 10)
                                    .split('-')
                                    .reverse()
                                    .join('/')}
                                </td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <h1 style={{ margin: '20px' }}>
                            {loading === true
                              ? ''
                              : 'Ainda não ha modificações!'}
                          </h1>
                        )}
                      </tbody>
                    </Table>
                    <ul id="page-numbers">
                      <nav>
                        <Pagination size="sm" style={{ display: 'flex', justifyContent: 'center' }}>
                          <PaginationItem>
                            {prev === 0 ? (
                              <PaginationLink disabled>{'<<'}</PaginationLink>
                            ) : (
                              <PaginationLink
                                onClick={this.handleFirstClick}
                                id={prev}
                                href={prev}
                              >
                                {'<<'}
                              </PaginationLink>
                            )}
                          </PaginationItem>
                          <PaginationItem>
                            {prev === 0 ? (
                              <PaginationLink disabled>{'<'}</PaginationLink>
                            ) : (
                              <PaginationLink
                                onClick={this.handleClick}
                                id={prev}
                                href={prev}
                              >
                                {'<'}
                              </PaginationLink>
                            )}
                          </PaginationItem>
                          {pageNumbers.map((number, i) => (
                            number >= currentPage - 4
                            && number <= currentPage + 4 ? (
                              <Pagination key={i}>
                                <PaginationItem
                                  active={pageNumbers[currentPage - 1] === number}
                                >
                                  <PaginationLink
                                    onClick={this.handleClick}
                                    href={number}
                                    key={number}
                                    id={number}
                                  >
                                    {number}
                                  </PaginationLink>
                                </PaginationItem>
                              </Pagination>
                              ) : null
                          ))}
                          <PaginationItem>
                            {currentPage === last ? (
                              <PaginationLink disabled>{'>'}</PaginationLink>
                            ) : (
                              <PaginationLink
                                onClick={this.handleClick}
                                id={pageNumbers[currentPage]}
                                href={pageNumbers[currentPage]}
                              >
                                {'>'}
                              </PaginationLink>
                            )}
                          </PaginationItem>
                          <PaginationItem>
                            {currentPage === last ? (
                              <PaginationLink disabled>{'>>'}</PaginationLink>
                            ) : (
                              <PaginationLink
                                onClick={this.handleLastClick}
                                id={pageNumbers[currentPage]}
                                href={pageNumbers[currentPage]}
                              >
                                {'>>'}
                              </PaginationLink>
                            )}
                          </PaginationItem>
                        </Pagination>
                      </nav>
                    </ul>
                  </>
                ) : (
                  'Ainda não ha modificações!'
                )}
                <CardFooter className="py-4">
                  <nav aria-label="..." />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Historic;
