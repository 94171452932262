import firebase from "firebase";
import 'firebase/storage';

var firebaseConfig = {
  apiKey: "AIzaSyBscePWnDxngc7d9Xf2gbsVWVITf5Onqb4",
  authDomain: "vamuvetv-c79d0.firebaseapp.com",
  databaseURL: "https://vamuvetv-c79d0.firebaseio.com",
  projectId: "vamuvetv-c79d0",
  storageBucket: "vamuvetv-c79d0.appspot.com",
  messagingSenderId: "798781609062",
  appId: "1:798781609062:web:ff50d625a3c2fde5"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export {
  storage, firebase as default
}
