import React from 'react';
import { Modal } from '@material-ui/core';
import alertImage from '../../assets/alert.png';
import '../../css/styles.css';

export default function AlertModal({
  show,
  onClick,
  title,
  subheader,
  onClose = () => {}
}) {
  return (
    <Modal open={show} onClose={onClose}>
      <div className='alert'>
        <div className='alert__image__container'>
          <img src={alertImage} alt='Alert icon' className='alert__image' />
        </div>
        <div className='alert__content'>
          <h1 className='alert__title'>{title}</h1>
          <h2 className='alert__subheader'>{subheader}</h2>
          <button className='alert__button' onClick={onClick}>
            Certo!
          </button>
        </div>
      </div>
    </Modal>
  );
}
