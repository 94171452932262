import React from 'react'

class Header extends React.Component {
  render () {
    return (
      <>
        <div className='header bg-gradient-orange pb-8' />
      </>
    )
  }
}

export default Header
