import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  CardBody,
  Input,
  Table,
  Col
} from "reactstrap";
import { Modal } from "@material-ui/core";
import Header from "../../../components/Headers/Header.jsx";
import SuccessModal from "../../../components/SuccessModal";
import AlertModal from "../../../components/AlertModal";
import { Link } from "react-router-dom";
import api from "../../../Services/api";
import EmailModal from "../../../components/EmailModal";

class User extends React.Component {
  constructor({ match }) {
    super();
    this.state = {
      id: match.params.id,
      successModal: false,
      alertModal: false,
      removeModal: false,
      loading: false,
      modal: false,
      redirect: false,
      search: null,
      reqSuccess: false,
      result: [],
      active: null,
      data: [],
      emailModal: false,
      clientModal: false,
      modal: []
    };
  }

  componentWillMount() {
    this.getUserData();
  }

  getUserData = () => {
    this.setState({ loading: true });
    api
      .get(`/usuarios/${this.state.id}/`)
      .then(resp => {
        if (resp.data.status) {
          this.setState({ loading: false });
          this.setState({ data: resp.data.response.message });
        } else {
          this.setState({ loading: false });
          this.setState({ data: this.state.result });
        }
      })
      .catch(error => {
        this.setState({ loading: true });
        console.log(error);
      });
  };

  buscarId(id) {
    this.setState({
      id: id
    });
    return id;
  }

  setActive(active) {
    if (active === 1) {
      this.setState({ active: 0 });
    } else {
      this.setState({ active: 1 });
    }
  }

  toggleModal = modal => {
    this.setState(state => ({
      [modal]: !state[modal]
    }));
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  confirmExclude = e => {
    e.preventDefault();
    api
      .post("/usuarios/moderar", {
        id: this.state.id,
        active: this.state.active
      })
      .then(response => {
        setTimeout(window.location.reload(), 1000);
      })
      .catch(error => {});
  };

  render() {
    const { successModal, data, removeModal } = this.state;
    return (
      <React.Fragment>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Col className="order-xl-1">
                <Card className="bg-secondary shadow">
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Informações do usuário
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Nome completo
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="lucky.jesse"
                                id="input-username"
                                placeholder="Username"
                                value={data.fullname}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Nome de usuário
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="jesse@example.com"
                                value={data.username}
                                type="email"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="Lucky"
                                id="input-first-name"
                                placeholder="First name"
                                value={data.email}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                CPF
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="Lucky"
                                id="input-first-name"
                                placeholder="First name"
                                value={
                                  data.cpf !== undefined
                                    ? `${data.cpf.slice(0, 3)}.${data.cpf.slice(
                                        3,
                                        6
                                      )}.${data.cpf.slice(
                                        6,
                                        9
                                      )}-${data.cpf.slice(9, 11)}`
                                    : ""
                                }
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Tipo de usuário
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="Lucky"
                                id="input-first-name"
                                placeholder="First name"
                                value={
                                  data.type_account === "Free"
                                    ? "Gratuito"
                                    : "Membro"
                                }
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Assinante desde:
                              </label>
                              {data.type_account === "Member" ? (
                                <Input
                                  className="form-control-alternative"
                                  defaultValue="Lucky"
                                  id="input-first-name"
                                  placeholder="First name"
                                  value={new Date(data.expire_signature)
                                    .toISOString()
                                    .substr(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                                  type="text"
                                  disabled
                                />
                              ) : (
                                <Input
                                  className="form-control-alternative"
                                  defaultValue="Lucky"
                                  id="input-first-name"
                                  placeholder="First name"
                                  value="Usuário não é assinante"
                                  type="text"
                                  disabled
                                />
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Situação
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="Lucky"
                                id="input-first-name"
                                placeholder="First name"
                                value={data.active === 1 ? "Ativo" : "Inativo"}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Endereço
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                Estado
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-postal-code"
                                placeholder="Postal code"
                                value={data.cep}
                                type="number"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                              >
                                Cidade
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="New York"
                                id="input-city"
                                placeholder="City"
                                value={data.city}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                Bairro
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="United States"
                                id="input-country"
                                placeholder="Country"
                                value={data.neighborhood}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                CEP
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-postal-code"
                                placeholder="Postal code"
                                value={data.cep}
                                type="number"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Endereço
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                                id="input-address"
                                placeholder="Home Address"
                                value={data.adress}
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                  <CardFooter className="py-4">
                    <nav aria-label="..." />
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({
                          modal: { fullname: data.fullname, email: data.email },
                          emailModal: true
                        });
                      }}
                    >
                      Enviar email
                    </Button>
                    <Button
                      color="primary"
                      className="py-2 px-3"
                      size="sm"
                      onClick={e => {
                        e.preventDefault();
                        this.toggleModal("removeModal");
                        this.buscarId(data.id);
                        this.setActive(data.active);
                      }}
                    >
                      Moderar
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </div>
          </Row>
          {removeModal && (
            <Modal
              open
              onClose={() => this.toggleModal("removeModal")}
              onSuccess={() => {
                this.toggleModal("removeModal");
                this.toggleModal("successModal");
              }}
              onError={() => this.toggleModal("alertModal")}
            >
              <div className="alert__content">
                <h1 className="alert__title">Aviso</h1>
                <h2 className="alert__subheader">Deseja realmente alterar?</h2>
                <button className="alert__button" onClick={this.confirmExclude}>
                  Confirmar
                </button>
              </div>
            </Modal>
          )}

          <EmailModal
            data={this.state.modal}
            open={this.state.emailModal}
            onClose={() => this.setState({ emailModal: false })}
          />
          <SuccessModal
            show={successModal}
            onClick={() => this.toggleModal("successModal")}
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default User;
