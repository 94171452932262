import React, { Component } from 'react'
import {
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap'
import { Modal } from '@material-ui/core'
import api from '../../Services/api'
import { Link } from "react-router-dom";
let prev = 0
let last = 0
class SerieList extends Component {
  constructor () {
    super()
    this.state = {
      requests: [],
      currentPage: 1,
      currentSerie: null,
      currentSeason: null,
      todosPerPage: 5,
      removeModal: false,
      reqSuccess: false,
      loading: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleLastClick = this.handleLastClick.bind(this)
    this.handleFirstClick = this.handleFirstClick.bind(this)
  }

  async componentDidMount () {
    this.setState({ loading: true })
    await api
      .post('/series')
      .then(res => {
        this.setState({
          reqSuccess: res.data.status,
          requests: res.data.response.message
        })
        this.setState({ loading: true })
      })
      .catch(error => console.log(error))
    this.setState({ loading: false })
    this.setState({
      requests: [...this.state.requests]
    })
    this.setState({ loading: false })
  }

  buscarId (id) {
    this.setState({
      id: id
    })
    return id
  }

  confirmExclude = e => {
    e.preventDefault()
    api
      .post('/series/deletar', {
        id_sr: this.state.id
      })
      .then(function (response) {
        setTimeout(window.location.reload(), 1000)
      })
      .catch(error => console.log(error))
  }

  handleClick (e) {
    e.preventDefault()
    this.setState({
      currentPage: Number(e.target.id)
    })
  }

  handleLastClick (e) {
    e.preventDefault()
    this.setState({
      currentPage: last
    })
  }
  handleFirstClick (e) {
    e.preventDefault()
    this.setState({
      currentPage: 1
    })
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    })
  }

  render () {
    let { requests, currentPage, todosPerPage, removeModal } = this.state

    let indexOfLastTodo = currentPage * todosPerPage
    let indexOfFirstTodo = indexOfLastTodo - todosPerPage
    let currentTodos = requests.slice(indexOfFirstTodo, indexOfLastTodo)
    prev = currentPage > 0 ? currentPage - 1 : 0
    last = Math.ceil(requests.length / todosPerPage)

    let pageNumbers = []
    for (let i = 1; i <= last; i++) {
      pageNumbers.push(i)
    }

    return (
      <>
        <Table className='align-items-center table-flush' responsive>
          <thead className='thead-light'>
            <tr>
              <th scope='col'>Título da Série</th>
              <th scope='col'>Temporadas</th>
              <th scope='col'>Data de lançamento</th>
              <th scope='col'>Poster</th>
              <th scope='col' />
            </tr>
          </thead>
          <tbody>
            {this.state.loading ? <i className='fa fa-spinner fa-pulse' /> : null}
            {this.state.reqSuccess ? (
              currentTodos.map((result, key) => {
                return (
                  <>
                    <tr key={key}>
                      <th scope='row'>
                        <span className='mb-0 text-sm'>{result.sr_title}</span>
                      </th>
                      <td>{result.total_seasons}</td>
                      <td>
                        {result.launch_date !== '' && result.launch_date !== null
                          ? result.launch_date
                            .substr(0, 10)
                            .split('-')
                            .reverse()
                            .join('/')
                          : (result.launch_date = '')}
                      </td>
                      <td>
                        {result.sr_poster === '' || result.sr_poster === null ? (
                          <img
                            className='img-thumbnail'
                            alt='...'
                            style={{ display: 'none' }}
                          />
                        ) : (
                          <img
                            className='img-thumbnail'
                            alt='...'
                            src={result.sr_poster}
                          />
                        )}
                      </td>
                      <td className='text-right'>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            style={{ color: '#fa6545' }}
                            onClick={e => e.preventDefault()}
                          >
                            <i className='fas fa-ellipsis-v' />
                          </DropdownToggle>
                          <DropdownMenu className='dropdown-menu-arrow' right>
                            <Link to={`/admin/series/editar/${result.slug}`}>
                              <DropdownItem>Editar</DropdownItem>
                            </Link>
                            <DropdownItem
                              onClick={() => {
                                this.toggleModal('removeModal')
                                this.buscarId(result.id_sr)
                              }}
                            >
                              Remover
                            </DropdownItem>
                            <DropdownItem>
                              <Link to={`/admin/serie/${result.id_sr}/temporadas`} style={{color:"black"}}>Ver Temporada</Link>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  </>
                )
              })
            ) : (
              <h1 style={{ margin: '20px' }}>
                {this.state.loading === true
                  ? ''
                  : 'Ainda não existe séries cadastradas!'}
              </h1>
            )}
          </tbody>
          {removeModal && (
            <Modal open onClose={() => this.toggleModal('removeModal')}>
              <div className='alert__content'>
                <h1 className='alert__title'>Aviso</h1>
                <h2 className='alert__subheader'>Deseja realmente excluir?</h2>
                <button className='alert__button' onClick={this.confirmExclude}>
                  Excluir
                </button>
              </div>
            </Modal>
          )}
        </Table>
        <ul id='page-numbers'>
          <nav>
            <Pagination size="sm" style={{ display: 'flex', justifyContent: 'center' }}>
              <PaginationItem>
                {prev === 0 ? (
                  <PaginationLink disabled>{'<<'}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleFirstClick}
                    id={prev}
                    href={prev}
                  >
                    {'<<'}
                  </PaginationLink>
                )}
              </PaginationItem>
              <PaginationItem>
                {prev === 0 ? (
                  <PaginationLink disabled>{'<'}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleClick}
                    id={prev}
                    href={prev}
                  >
                    {'<'}
                  </PaginationLink>
                )}
              </PaginationItem>
              {pageNumbers.map((number, i) => (
                number >= currentPage - 4
                && number <= currentPage + 4 ? (
                  <Pagination key={i}>
                    <PaginationItem
                      active={pageNumbers[currentPage - 1] === number}
                    >
                      <PaginationLink
                        onClick={this.handleClick}
                        href={number}
                        key={number}
                        id={number}
                      >
                        {number}
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                  ) : null
              ))}

              <PaginationItem>
                {currentPage === last ? (
                  <PaginationLink disabled>{'>'}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleClick}
                    id={pageNumbers[currentPage]}
                    href={pageNumbers[currentPage]}
                  >
                    {'>'}
                  </PaginationLink>
                )}
              </PaginationItem>

              <PaginationItem>
                {currentPage === last ? (
                  <PaginationLink disabled>{'>>'}</PaginationLink>
                ) : (
                  <PaginationLink
                    onClick={this.handleLastClick}
                    id={pageNumbers[currentPage]}
                    href={pageNumbers[currentPage]}
                  >
                    {'>>'}
                  </PaginationLink>
                )}
              </PaginationItem>
            </Pagination>
          </nav>
        </ul>
     </>
    )
  }
}

export default SerieList
