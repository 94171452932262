import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Modal } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Header from '../../../components/Headers/Header';
import ActorList from '../../../components/ActorList';
import api from '../../../Services/api';
import SnackBar from '../../../components/Snackbar';

class Cast extends React.Component {
  state = {
    loading: false,
    removeModal: false,
    search: null,
    reqSuccess: false,
    result: null,
    modal: false,
    redirect: false,
    snack: false,
    snackMessage: null,
    snackVariant: null,
  }

  buscarId(id) {
    this.setState({
      id,
    });
    return id;
  }

  confirmExclude = (e) => {
    e.preventDefault();
    api
      .post('/elenco/deletar', {
        id_ct: this.state.id,
      })
      .then((response) => {
        setTimeout(window.location.reload(), 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggleModal = (modal) => {
    this.setState(state => ({
      [modal]: !state[modal],
    }));
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  }

  search = async (e) => {
    e.preventDefault();
    this.setState({
      search: e.target.search.value,
      reqSuccess: null,
      loading: true,
    });
    const search = e.target.search.value;
    await api
      .post('elenco/pesquisar', {
        filter: search,
      })
      .then((resp) => {
        console.log(resp);
        if (resp.data.status) {
          this.setState({ loading: false });
          this.setState({ reqSuccess: true });
          this.setState({ result: resp.data.response.message });
        } else {
          this.setState({
            loading: false,
            snack: true,
            snackMessage: 'Ator e/ou Diretor não encontrado!',
            snackVariant: 'error',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          snackMessage: `Erro de comunicacão com servidor : ${error}`,
          snackVariant: 'error',
        });
        console.log(error);
      });
  }

  render() {
    const { removeModal } = this.state;

    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Elenco</h3>
                </CardHeader>
                <Form
                  className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex"
                  onSubmit={this.search}
                >
                  <FormGroup className="mb-0">
                    <InputGroup className="input-group-alternative" style={{ backgroundColor: 'white', marginLeft: '20px', marginBottom: '20px' }}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" style={{ color: 'black' }} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Pesquisar"
                        name="search"
                        type="text"
                        style={{ color: 'black' }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
                {this.state.result ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Nome do Diretor/Ator</th>
                        <th scope="col">Local de Nascimento</th>
                        <th scope="col">Local de Falecimento</th>
                        <th scope="col">Foto</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.loading ? (
                        <i className="fa fa-spinner fa-pulse" />
                      ) : null}
                      {this.state.reqSuccess ? (
                        this.state?.result?.map((result, key) => (
                          <tr key={key}>
                            <th scope="row">
                              <span className="mb-0 text-sm">
                                {result.fullname}
                              </span>
                            </th>
                            <td>{result.birthplace == null ? 'Desconhecido' : result.birthplace}</td>
                            <td>{result.deadplace == null ? 'Desconhecido' : result.deadplace}</td>
                            <td>
                              {result.profile_image === null
                                || result.profile_image === '' ? (
                                  <img
                                    className="img-thumbnail"
                                    alt="..."
                                    style={{ display: 'none' }}
                                  />
                                ) : (
                                  <img
                                    className="img-thumbnail"
                                    alt="..."
                                    src={result.profile_image}
                                  />
                                )}
                            </td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only"
                                  href="#"
                                  role="button"
                                  size="sm"
                                  color=""
                                  style={{ color: '#fa6545' }}
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <Link
                                    to={`/admin/elenco/editar/${result.slug}`}
                                  >
                                    <DropdownItem>Editar</DropdownItem>
                                  </Link>
                                  <DropdownItem
                                    onClick={() => {
                                      this.toggleModal('removeModal');
                                      this.buscarId(result.id_ct);
                                    }}
                                  >
                                    Remover
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <h1 style={{ margin: '20px' }}>
                          {this.state.loading === true
                            ? ''
                            : 'Ainda não existe elenco cadastrado!'}
                        </h1>
                      )}
                    </tbody>

                    {removeModal && (
                      <Modal
                        open
                        onClose={() => this.toggleModal('removeModal')}
                      >
                        <div className="alert__content">
                          <h1 className="alert__title">Aviso</h1>
                          <h2 className="alert__subheader">
                            Deseja realmente excluir?
                          </h2>
                          <button
                            className="alert__button"
                            onClick={this.confirmExclude}
                          >
                            Excluir
                          </button>
                        </div>
                      </Modal>
                    )}
                  </Table>
                ) : (
                  <ActorList />
                )}
                <CardFooter className="py-4">
                  <nav aria-label="..." />
                  <Link to="/admin/elenco/adicionar">
                    <Button color="primary" className="py-2 px-3" size="sm">
                      Adicionar Elenco
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <SnackBar
          isOpen={this.state.snack}
          isVariant={this.state.snackVariant}
          isMessage={this.state.snackMessage}
          isClose={() => this.setState({ snack: false })}
        />
      </>
    );
  }
}

export default Cast;
